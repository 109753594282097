.admin-jobs-card.job-card-view .active-jobs-info {
    padding-top: 0;
}
.tj-jobs {
    display: inline-flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 8px;
    background: #E7F2FF;
    margin-bottom: 8px;
}
.tj-jobs .tj-jobs-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14.154px;
    height: 16px;
}
.tj-jobs .tj-jobs-logo img {
    max-width: 100%;
    height: auto;
}
.tj-jobs .tj-jobs-txt {
    color: #002149;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
.admin-jobs-card.job-card-view .ctc-range {
    padding: 16px 0!important;
}
.admin-job-details .posted-date {
    color: #4B5563;
    text-align: right;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
.shared-candi-profile {
    position: relative;
    padding: 12px;
    border-radius: 8px;
    background: var(--Neutral-100, #E7EBFD);
    margin: 0 20px 24px;
}
.shared-candi-profile .shared-count-txt p {
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.shared-candi-profile .shared-count-txt p span {
    font-size: 24px;
}
.shared-candi-profile .view-details-txt {
    color: var(--Primary-Light, #1575C6);
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.admin-dash-det-card.card-wrapper .card-content {
    padding: 16px !important;
    border: none !important;
    background-color: #F5F7FF;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.admin-dash-det-card.card-wrapper .card-content-bottom {
    border-right: 1px solid #F5F7FF;
    border-bottom: 1px solid #F5F7FF;
    border-left: 1px solid #F5F7FF;
    background: #FFF;
    background-color: #ffffff !important;
    border-radius: 12px !important;
    overflow: hidden;
    z-index: 2;
    position: relative;
    padding: 12px 16px;
    gap: 0;
}
.admin-dash-det-card.not-suitable-card .card-content {
    background-color: #AFD2FF;
}
.admin-dash-det-card.not-suitable-card .card-content-bottom {
    background-color: #D9E8FB!important;
}
.select-status-dp-menu .MuiInputBase-root {
    background-color: #ffffff;
}
.select-status-dp-menu fieldset {
    border-color: #1575C6;
}
.admin-filter-search {
    padding:0;
}
.admin-filter-search.active-jobs-list .header-wrapper {
    padding: 0;
    background-color: #ffffff;
}
.admin-filter-search.active-jobs-list .header-wrapper .head-text {
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}
.admin-filter-search.active-jobs-list .header-wrapper .filter-wrapper {
    margin-bottom: 24px!important;
}
.shortlisted-tab-header p {
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}
.shortlisted-tab-header .MuiButtonBase-root {
    color: var(--White, #FFF);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding: 4px 12px;
    border-radius: 4px;
    background: var(--CTA-Active, #1575C6);
    text-transform: capitalize;
}
.feedback-link {
    color: var(--CTA-Active, #1575C6)!important;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif!important;
    font-size: 12px!important;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
.view-add-feedback {
    position: relative;
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid #E7EBFD;
    margin-top: 16px;
}
.view-add-feedback p {
    color: var(--Neutral-600, #4B5563);
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif!important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
.select-status-dp-menu .MuiSelect-select {
    color: var(--CTA-Active, #1575C6)!important;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif!important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 500!important;
}
.select-status-menu-item {
    color: var(--CTA-Active, #1575C6)!important;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 16px!important;
}
.candidate-profile-toggle {
    padding: 4px 5px;
    border-radius: 8px!important;
    border: 1px solid var(--Neutral-200, #C5D1DB);
    background: var(--Neutral-50, #F9FAFF);
}
.candidate-profile-toggle button {
    border: none;
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    padding: 5px 10px;
    text-transform: capitalize;
}
.candidate-profile-toggle button:hover {
    background-color: transparent;
}
.candidate-profile-toggle button.Mui-selected,
.candidate-profile-toggle button.Mui-selected:hover {
    border-radius: 8px;
    background: var(--CTA-Active, #1575C6);
    color: #ffffff;
}
.tj-feedback-wrapper .ratings-title {
    color: var(--Neutral-800, #1F2937);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.12px;
}
.tj-feedback-wrapper .more-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    flex: 1 0;
    overflow: hidden;
    color: #374151;
    font-feature-settings: 'ss01' on;
    text-overflow: ellipsis;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.tj-feedback-wrapper .done-btn {
    color: var(--White, #FFF);
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    border-radius: 8px;
    padding: 4px 8px;
    border: 1.064px solid var(--CTA-Active, #1575C6);
    background: var(--Primary-Light, #1575C6);
    text-transform: capitalize;
}
.que-res-wrapper .que-txt {
    color: var(--Neutral-500, #6B7280);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.12px;
}
.que-res-wrapper .res-txt {
    color: var(--Neutral-900, #111827);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.12px;
}
.add-observations p {
    color: var(--Neutral-800, #1F2937);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.12px;
}
.add-observations a {
    color: var(--CTA-Active, #1575C6);
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.admin-active-jobs-wrapper {
     position: relative;
     display: block;
     width: 100%;
     height: 100%;
    overflow: hidden;
}
.admin-active-jobs-content {
     position: relative;
     width: 100%;
     height: calc(82vh - 130px);
     overflow-y: auto;
}
.edit-job-det-btn {
    color: var(--CTA-Active, #1575C6)!important;
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 24px!important; /* 150% */
    text-transform: capitalize!important;
    border-radius: 12px!important;
    border: 1px solid var(--CTA-Active, #1575C6);
}
.backButton {
    color: var(--Neutral-900, #111827)!important;
    font-family: "Inter", sans-serif!important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize!important;
}
.backButton svg {
    width: 20px!important;
    height: 20px!important;
}
.skills-match-card .title {
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.skills-match-card .MuiChip-root {
    position: relative;
    border-radius: 8px;
    border: 0.5px solid var(--Success-600, #D2D5DB);
    background: rgba(231, 255, 234, 0.00);
    padding: 4px 8px;
    margin-right: 12px;
    margin-bottom: 12px;
    height: 25px;
}
.skills-match-card .MuiChip-root .MuiChip-icon {
    height: 12px;
    width: 12px;
    position: absolute;
    right: 0;
    bottom: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}
.skills-match-card .MuiChip-root .MuiChip-label {
    color: var(--Neutral-900, #111827);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-right: 0;
    padding-left: 0;
}
.admin-job-description .title {
    color: #111827;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0.7rem;
}
.admin-job-description .info-text {
    overflow: hidden;
    color: var(--Neutral-500, #6B7280);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.12px;
}
.admin-job-description .job-desc-list li {
    color: var(--Neutral-500, #6B7280);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.12px;
}