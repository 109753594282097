.mobileInputBoxContainer {
    width: 100%;
}

.mobileInputBoxContainer input {
    width: calc(100% - 60px) !important;
}

.mobileInputBoxContainerWithCode {
    width: 100%;
}

.mobileInputBoxContainerWithCode input {
    width: calc(100% - 95px) !important;
}

.mobileInputBoxSizing {
    box-sizing: content-box !important;
}

.onFocus:focus-within {
    border-color: rgb(53, 209, 245);
    border-width: 2px;
    outline: 0px;
    border-radius: 5px;
    box-shadow: rgb(129, 227, 249) 0rem 0rem 0.2rem;
}

.dotedBorder {
    margin-top: 10px;
    border: 2px dashed lightgrey;
   
    border-radius: 5px;
}

.inlineText {
    padding-top:10px
}

.centerBtn {
    display: flex;
    position: sticky;
    bottom: -8px;
    justify-content: center;
    background-color: white;
    width: 100%;
    padding: 20px 0;
}
.w100{
    width: 100% !important;
}

