* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  /* overflow: hidden; */
}

/* Hide scrollbars for Chrome, Safari, and Opera */
html::-webkit-scrollbar,
body::-webkit-scrollbar,
body *::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbars for IE, Edge, and Firefox */
html,
body,
body * {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* Inherit MUI Theme Style */
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--Light-Neutral-500, var(--Neutral-500, #6b7280));
  /* Paragraph/Small/Regular */
  /* font-family: 'Inter', sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background: var(--Neutral-50, #f9faff);
}

.css-ysycdn-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled svg {
  color: var(--Neutral-300, #D2D5DB);
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
  color: var(--Neutral-300, #D2D5DB);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  /* -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important; */
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  color: var(--Neutral-300, #D2D5DB);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

/* .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  font-size: 14px;
  font-weight: 500;
} */
.page-content {
  padding: 80px 25px 50px;
}

.wh-number-input {
  position: relative;
}

.wh-number-input .MuiInputBase-root .MuiInputBase-input {
  padding-left: 5.5rem;
}

.iti-flag-container {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 1;
}

.iti-flag-container .iti-flag {
  height: 14px;
  background-position: -2413px 0px;
  width: 20px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url('components/assets/flags.png');
  background-repeat: no-repeat;
  background-color: #dbdbdb;
}

.custom-svg-icon {
  width: 16px;
  height: 16px;
}

.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  background: var(--TJ-Grad, linear-gradient(90deg, #1b1464 -3.92%, #2099d6 233.8%));
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}

.header .logout-btn {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 24px;
  /* 150% */
  border-radius: 8px;
  border: 1px solid var(--White, #fff);
}

.header .header-bottom-space {
  display: flex;
  width: 1024px;
  height: 58px;
  padding: 20px var(--Horizontal-Spacing-Header, 44px) 0px var(--Horizontal-Spacing-Header, 44px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

/* Login Page Style */
.login-page-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-info-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--TJ-Grad, linear-gradient(90deg, #1b1464 -3.92%, #2099d6 233.8%));
  text-align: center;
}

.login-content-wrapper {
  position: relative;
  width: 100%;
  padding: 50px;
}

.login-content-wrapper .MuiFormControl-root .MuiInputBase-root {
  height: 44px;
}

/* .login-content-wrapper .tj-logo {
  display: inline-block;
  position: absolute;
  top: 0;
} */
.login-content-wrapper .tj-logo {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 196px;
}

.login-content-wrapper .MuiFormControl-root .MuiInputBase-input {
  /* color: var(--Light-Neutral-500, #6b7280) !important; */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.login-content-wrapper .MuiFormControl-root .MuiInputBase-input::placeholder {
  color: var(--Light-Neutral-500, #6b7280) !important;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.login-content-wrapper .remember-me-chk .MuiTypography-root {
  color: var(--Neutral-600, #4b5563);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  /* 150% */
}

.login-content-wrapper .fogot-pass-link {
  color: var(--CTA-Active, #1575c6);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  /* 150% */
}

.login-content-wrapper .login-btn {
  background: var(--Accent-Light, #1f9cd7);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* Paragraph/Medium/Semi Bold */
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize !important;
  border-radius: 12px;
  height: 48px;
}

.login-content-wrapper .disable-login-btn {
  background: var(--Accent-Light, #b8dced);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* Paragraph/Medium/Semi Bold */
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize !important;
  border-radius: 12px;
  height: 48px;
}

.login-btn.Mui-disabled {
  color: #ffffff !important;
  background-color: #cce0f4 !important;
}

/* .extLoginIcon img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
} */
.ext-login-btn {
  color: var(--Neutral-900, #111827) !important;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  /* 171.429% */
  border-radius: var(--Spacing-4x, 12px) !important;
  border: 1px solid var(--Primary-Light, #2aaae2) !important;
  text-transform: capitalize !important;
}

.login-content-wrapper .register-link {
  color: var(--Neutral-900, #111827);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.login-info-wrapper .text-primary {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login-info-wrapper .text-secondary {
  color: var(--Neutral-400, #afb1bb);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-info-wrapper svg {
  width: -webkit-fill-available !important;
  height: auto;
}

/* Forgot passwod dialog  */

.forgot-password-dialog .MuiPaper-root {
  width: 510px;
}

.forgot-password-dialog .MuiPaper-root {
  border-radius: 16px;
  background: #fff;
}

.forgot-password-dialog .MuiCard-root .MuiCardContent-root,
.forgot-password-dialog .MuiCard-root .MuiCardActions-root {
  padding: var(--Spacing-6x, 20px);
}

.forgot-password-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.forgot-password-dialog .info-text {
  color: var(--Light-Neutral-600, #4b5563);
  /* font-family: 'Inter', sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.forgot-password-dialog .MuiFormControlLabel-root {
  display: flex;
  padding: var(--Spacing-4x, 12px);
  align-items: flex-start;
  gap: var(--Spacing-4x, 12px);
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #c5d1db);
  margin-right: 0;
}

.forgot-password-dialog .option-title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.forgot-password-dialog .option-text {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.forgot-password-dialog .cancel-btn {
  color: var(--CTA-Active, #1575c6);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  padding: 12px 20px;
  text-transform: capitalize;
}

.forgot-password-dialog .continue-btn {
  color: var(--White, #fff);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  border-radius: var(--Spacing-4x, 12px);
  /* background: var(--Accent-Neutral, #CCE0F4); */
  text-transform: capitalize;
  padding: 12px 20px;
}

.forgot-password-form .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.forgot-password-form .info-text {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}
.registration-upload-page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}
.registration-upload-page .regi-upload-page-content {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 100px); */
  height: 100vh;
  overflow-y: auto;
  padding: 100px 42px 42px;
}
.registration-upload-page .regi-upload-page-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.register-form-wrapper .text-primary {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.register-form-wrapper .text-primary a {
  color: var(--Accent-Dark, #3d1c9b);
  font-weight: 700;
}
.resume-type-toggle-btn .MuiButtonBase-root {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  border: 1px solid #4B5563!important;
  border-radius: 8px!important;
}
.resume-type-toggle-btn .MuiButtonBase-root:hover {
  background-color: transparent!important;
}
.resume-type-toggle-btn .MuiButtonBase-root.Mui-selected {
  background: var(--CTA-Active, #1575C6)!important;
  color: #ffffff!important;
  border: none!important;
}
.register-form-wrapper .text-secondary {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  text-align: center;
}

.register-form-wrapper .text-secondary a {
  color: var(--CTA-Active, #1575c6);
}

.register-form-wrapper .MuiFormControl-root .MuiFormLabel-root {
  line-height: 26px;
}

.register-form-wrapper .iti-flag-container {
  top: 13px;
}

.register-form-wrapper .password-field {
  position: relative;
}

.register-form-wrapper .password-field .MuiFormLabel-root {
  left: 2rem;
}

.register-form-wrapper .password-field .MuiFormLabel-root.Mui-focused,
.register-form-wrapper .password-field .MuiFormLabel-root.MuiFormLabel-filled {
  left: 0;
}

.register-form-wrapper .password-field .MuiInputBase-input {
  padding-left: 2.5rem;
}

.register-form-wrapper .password-field .password-lock-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.resume-onboarded-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: auto;
  justify-content: space-between;
}
.resume-onboarded-card .card-top {
  display: flex;
  width: 100%;
  padding: 20px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--Neutral-100, #E7EBFD);
  background: var(--White, #FFF);
  position: relative;
  z-index: 2;
}
.resume-onboarded-card.selected .card-top {
  background-color: #E8F1F9;
  border: 1px solid #5462FF;
}
.resume-onboarded-card .card-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: var(--Primary-Light, #5462FF);
  width: 100%;
  padding: 22px 16px 12px 16px;
  z-index: 1;
  margin-top: -10px;
  cursor: pointer;
}
.resume-onboarded-card .card-bottom p {
  color: var(--White, #FFF);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.resume-onboarded-card .recommended-label {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: -12px;
  border-radius: 4px;
  border: 0.5px solid var(--Success, #29C138);
  background: #E2FFE5;
}
.resume-onboarded-card .recommended-label p {
  color: var(--Success-600, #0E7819);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
}
.resume-onboarded-card .MuiFormControlLabel-root {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: none;
}
.resume-onboarded-card .MuiRadio-root {
  padding: 0;
}
.resume-onboarded-card .MuiFormControlLabel-label {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}
.resume-onboarded-card .list-item p {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.resume-onboarded-card-container .MuiButton-root.Mui-disabled {
  color: #ffffff;
  background-color: #CCE0F4;
}
.resume-onboarded-card .MuiRadio-root .MuiSvgIcon-root path {
  fill: #D2D5DB;
}
.resume-onboarded-card .MuiRadio-root.Mui-checked .MuiSvgIcon-root,
.resume-onboarded-card .MuiRadio-root.Mui-checked .MuiSvgIcon-root path {
  fill: #1C3FCA;
}
/* == Close Login Page Style == */

/* OTP Verification Style */
.otp-dialog-wrapper .MuiPaper-root {
  border-radius: var(--Spacing-4x, 20px) !important;
  width: 550px;
}

.otp-card-wrapper {
  border-radius: var(--Spacing-4x, 20px) !important;
  border: 1.005px solid var(--Neutral-300, #d2d5db);
  background: #fff;
}

.otp-card-wrapper .MuiCardContent-root {
  padding: 24px 32px;
}

.otp-icon {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  /* transform: translateX(10px); */
}

.otp-icon img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.otp-icon .icon-layer {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  background: #1e4a92;
  mix-blend-mode: hue;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.otp-card-wrapper .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.otp-card-wrapper .otp-sent-txt {
  color: var(--Light-Neutral-600, var(--Neutral-600, #4b5563));
  text-align: center;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.otp-card-wrapper .resend-otp-txt {
  color: var(--Light-Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.otp-card-wrapper .resend-otp-txt span {
  color: var(--light-neutral-900-text, #111827);
  font-weight: 600;
}

.otp-card-wrapper .attempts-txt {
  color: var(--Light-Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.otp-card-wrapper .otp-btn {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  padding: 12px 15px;
}

.otp-card-wrapper .Mui-disabled.otp-btn {
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.otp-card-wrapper .close-dialog-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 1;
}

/* == End == */

.candidate-details-content {
  position: relative;
  width: 100%;
  padding: 90px 40px 20px;
}

.details-stepper-wrapper .stepper-card {
  border-radius: var(--Spacing-3x, 20px);
  border: 1px solid var(--Light-Neutral-200, #ecedf3);
  background: #fff;
  /* Elevation/Small */
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.details-stepper-wrapper .MuiStepButton-root .MuiSvgIcon-root {
  border-radius: var(--Spacing-3x, 20px);
  border: 1px solid var(--Neutral-500, #6b7280);
  fill: var(--White, #fff);
}

.details-stepper-wrapper .MuiStepButton-root .MuiStepIcon-text {
  fill: var(--Neutral-500, #6b7280);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.details-stepper-wrapper .MuiStepButton-root .MuiSvgIcon-root.Mui-active {
  border-radius: var(--Spacing-3x, 20px);
  border: 0px solid var(--Primary-Dark, #002149);
  fill: var(--Primary-Dark, #002149);
}

.details-stepper-wrapper .MuiStepButton-root .MuiSvgIcon-root.Mui-active .MuiStepIcon-text {
  fill: var(--White, #fff);
}

.details-stepper-wrapper .MuiStepButton-root .MuiStepLabel-label {
  color: var(--Neutral-500, #6b7280);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.details-stepper-wrapper .MuiStepButton-root .MuiStepLabel-label.Mui-active {
  color: var(--Primary-Dark, #002149);
}

.details-stepper-wrapper .MuiStepConnector-root {
  top: 30px;
}

.details-stepper-wrapper .MuiStepConnector-root .MuiStepConnector-line {
  display: block;
  border-color: #ecedf3;
  border-top-style: solid;
  border-top-width: 3px;
  width: 40%;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  border-radius: 10px;
}

.candidate-det-card {
  padding: 32px var(--Spacing-6x, 20px) var(--Spacing-6x, 20px) var(--Spacing-6x, 20px);
  border-radius: var(--Spacing-6x, 20px) !important;
  background: #fff;
  border: 1px solid var(--Light-Neutral-200, #ecedf3);
  background: var(--Accent-Neutral, #cce0f4);
  /* Elevation/Small */
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.step-heading {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  /* font-family: "Inter", sans-serif!important; */
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 4px !important;
}

.step-info {
  color: var(--Light-Neutral-600, #4b5563);
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px;
  /* 150% */
}

.disability-card {
  /* border-radius: 16px !important; */
  /* background: var(--Secondary-Grey-Light, #f1f5f7) !important; */
  border: none !important;
  /* box-shadow: none !important; */
}

.disability-card .MuiCardContent-root {
  padding: 16px !important;
  background-color: #F1F5F7;
  border-radius: 16px;
}

.disability-card-v1 .MuiCardContent-root {
  padding: 16px 0 !important;
}

.disability-card .heading {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.disability-card .info {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  margin: 0;
}

.disability-card .MuiInputBase-root {
  background-color: #ffffff;
}

.candidate-basic-det-wrapper .info-list-item {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--White, #fff);
  margin-bottom: 1rem;
  cursor: pointer;
}

.candidate-basic-det-wrapper .info-list-item .title-wrapper {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.candidate-basic-det-wrapper .info-list-item.incomplete-details {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Destructive, #c12929);
  background: var(--White, #fff);
}

.candidate-basic-det-wrapper .info-list-item.incomplete-details .incomplete-tag {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: var(--Destructive, #c12929);
  color: #fff;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.candidate-basic-det-wrapper .info-list-item .title-wrapper {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.candidate-basic-det-wrapper .info-list-item .info-wrapper {
  color: var(--light-neutral-900-text, var(--Neutral-900, #111827));
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  margin: 0;
}

.info-list-item .delete-sec-btn,
.info-list-item .delete-sec-btn:hover {
  border-radius: 4px;
  background: #fee;
}

.delete-sec-btn,
.delete-sec-btn:hover {
  border-radius: 4px;
  background: #fee;
}

.info-sec-btn,
.info-sec-btn:hover {
  border-radius: 4px;
  background: rgb(234, 237, 255);
}

.candidate-basic-det-wrapper .form-wrapper {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
}

.candidate-basic-det-wrapper .form-wrapper .delete-sec-btn,
.candidate-basic-det-wrapper .form-wrapper .delete-sec-btn:hover {
  border-radius: 4px;
  background: #fee;
}

.candidate-basic-det-wrapper .form-wrapper .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: var(--Spacing-4x, 12px);
  background: #fff;
}

.candidate-basic-det-wrapper .form-wrapper .main-title,
.education-details-form .main-title,
.work-exp-details-form .main-title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  margin-bottom: 1rem;
}

.besic-det-skills-wrapper .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.besic-det-skills-wrapper .info-txt {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.besic-det-skills-wrapper .MuiChip-root {
  border-radius: 4px;
  background: rgba(42, 170, 226, 0.1);
  margin-right: 8px;
  margin-bottom: 8px;
}

.besic-det-skills-wrapper .MuiChip-label {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.besic-det-skills-wrapper .add-more-skills-link {
  color: var(--Primary-Light, #2aaae2);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  cursor: pointer;
}

.add-more-technology-link {
  color: var(--CTA-Active, #1575c6);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.accomplishments .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.accomplishments .info-txt {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.candidate-basic-det-wrapper .cancel-btn {
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.ftc-checkbox-wrapper .MuiFormControlLabel-root .MuiTypography-root {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.candidate-basic-det-wrapper .done-btn {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* Paragraph/Medium/Semi Bold */
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  height: var(--Card-Bg-Height, 48px);
  padding: 8px var(--Spacing-6x, 56px);
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Accent-Light, #1f9cd7);
}

.candidate-basic-det-wrapper .Mui-disabled.done-btn {
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.candidate-basic-det-wrapper .add-btn {
  color: var(--Primary-Light, #2aaae2);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
  text-transform: capitalize;
  margin-left: 8px;
}

.prof-summary-wrapper .prof-summary-card {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-300, #d2d5db);
  background: #fff;
}

.prof-summary-wrapper .ai-gen-text {
  color: var(--light-neutral-900-text, var(--Neutral-900, #111827));
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.ai-help-btn {
  color: var(--CTA-Active, #2aaae2);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  /* margin-top: 1; */
  /* padding-top: 1; */
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
}

.prof-summary-wrapper .add-btn {
  color: var(--Primary-Light, #2aaae2);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
  text-transform: capitalize;
}

.ai-icon {
  display: flex;
  width: var(--Spacing-6x, 20px);
  height: var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--CTA-Active, #1575c6);
}

.ai-icon svg {
  width: 10.96px;
  height: var(--Spacing-4x, 12px);
  flex-shrink: 0;
}

.add-custom-section-wrapper .cust-sec-card {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-300, #d2d5db);
  background: #fff;
}

.add-custom-section-wrapper .cust-sec-text {
  color: var(--light-neutral-900-text, var(--Neutral-900, #111827));
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.add-custom-section-wrapper .delete-sec-btn {
  background-color: #fee;
  border-radius: 4px;
}

.custom-sec-list .MuiList-root .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.add-cus-sec-btn {
  color: var(--CTA-Active, #1575c6) !important;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  /* 133.333% */
  text-transform: capitalize !important;
}

.next-btn {
  border-radius: var(--Spacing-4x, 12px) !important;
  background: var(--Primary-Light, #1575c6) !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  /* 150% */
  padding: 8px 60px !important;
  text-transform: capitalize !important;
}

.next-btn.Mui-disabled {
  color: #ffffff !important;
  background-color: #cce0f4 !important;
}

.upload-profile-photo-info .step-info a {
  color: #1575c6;
}

.upload-photo-wrapper {
  position: relative;
}

.upload-photo-wrapper .replace-profile-pic {
  display: inline-flex;
  width: 50px;
  height: 50px;
  background: #000000;
  font-size: 10px;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  opacity: 0.6;
}

.candidate-resume-wrapper {
  position: fixed;
  padding: 75px 20px 20px 20px;
  border: 1px solid var(--Neutral-200, #002149);
  background: var(--Neutral-50, #002149);
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  height: 100%;
  width: -webkit-fill-available;
}

.resume-body {
  height: 90vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  /* border-radius: 15px; */
}

.resume-body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.resume-body .resume-container {
  margin-top: -80px;
}

.back-button {
  margin-top: -3px;
}

.candidate-resume-wrapper .header-sec .title {
  color: var(--Neutral-900, #ffffff);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
}

.choose-template {
  color: var(--CTA-Active, #1575c6);
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
  text-decoration: none;
  background: none;
  border: none;
  /* cursor: pointer; */
  margin-left: auto;
}

.candidate-resume-wrapper .header-sec svg {
  width: 16px;
  height: 16px;
}

.candidate-resume-wrapper .header-sec .download-resume {
  width: 20px;
  height: 20px;
}

.ftc-checkbox-wrapper .MuiFormControlLabel-root .MuiTypography-root {
  /* color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal; */
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.resume-container .resume-card {
  border-radius: var(--Spacing-4x, 12px);
  border: 1.005px solid var(--Neutral-300, #d2d5db);
  /* background: #fff; */
}

.resume-container .resume-profile-pic img {
  border-radius: 4.8px;
}

.resume-container .resume-card .candidate-personal-info {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.resume-container .resume-card .candidate-name {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif !important;
  font-size: 16.074px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.121px;
  /* 162.5% */
}

.resume-container .resume-card .candidate-email-no {
  color: var(--Secondary-Grey-Dark, #374151);
  font-family: 'Inter', sans-serif !important;
  font-size: 12.056px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.084px;
  /* 150% */
}

.resume-container .resume-card .resume-info-header-txt {
  color: var(--Secondary-Grey-Dark, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12.768px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.153px;
  /* 150% */
}

.resume-container .resume-card .resume-info-header-main {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 12.768px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.153px;
  /* 150% */
}

.candidate-personal-info .list-item .MuiBox-root {
  position: relative;
}

.candidate-personal-info .list-item .MuiBox-root:last-child::before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #808080;
  position: absolute;
  top: 5px;
  left: -10px;
  border-radius: 24px;
}

.candidate-personal-info .degree-name,
.candidate-personal-info .degree-type,
.candidate-personal-info .year-of-passout,
.candidate-personal-info .company-name,
.candidate-personal-info .work-type,
.candidate-personal-info .working-year {
  color: var(--Secondary-Grey-Dark, #374151);
  font-family: 'Inter', sans-serif !important;
  font-size: 10.64px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.961px;
  /* 150% */
}

.personal-edu-text .educational-info-txt,
.personal-work-text .work-info-txt {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 150% */
}

.personal-edu-text ul li::marker,
.personal-work-text ul li::marker {
  color: #002149;
}

.candidate-highlighted-skills-sec .header-block {
  border-radius: 8px 8px 0px 0px;
  background: var(--Neutral-50, #f9faff);
  padding: 4px;
}

.candidate-highlighted-skills-sec .header-block .MuiTypography-root {
  display: inline-block;
  color: var(--Neutral-500, #6b7280);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 10.64px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.candidate-highlighted-skills-sec .text-block .MuiTypography-root {
  display: inline-block;
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

/* Add Skills Style */
.add-skills-dialog .MuiPaper-root {
  border-radius: var(--Spacing-4x, 20px) !important;
  width: 550px;
}

.add-skills-dialog {
  position: relative;
  border-radius: var(--Spacing-4x, 20px) !important;
  border: 1.005px solid var(--Neutral-300, #d2d5db);
  background: #fff;
}

.add-skills-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.add-skills-dialog .sub-text {
  color: var(--Light-Neutral-600, var(--Neutral-600, #4b5563));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.add-skills-dialog .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: var(--Neutral-900, #111827);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.add-skills-dialog .error-text {
  color: var(--Destructive, #c12929);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.add-skills-dialog .MuiCardContent-root {
  padding: 24px 32px;
}

.add-skills-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.add-skills-dialog .add-skills-dialog-content {
  position: relative;
  height: 315px;
  overflow: hidden;
  padding-bottom: 4rem;
  padding-top: 1rem;
}

.add-skills-dialog .add-skills-dialog-content .content-inner {
  height: 35vh;
  overflow-y: scroll;
  max-height: 100vh;
}

.add-skills-dialog .add-skills-dialog-content .content-inner::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.add-skills-dialog .skills-dialog-actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  padding: 1.5rem 2rem;
}

.add-skills-dialog .otp-btn {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  padding: 12px 15px;
}

.add-skills-dialog .Mui-disabled.otp-btn {
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.add-skills-dialog .close-dialog-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 1;
}

/* == End == */

.back-btn {
  color: var(--Neutral-900, #111827) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  /* 142.857% */
  text-transform: capitalize !important;
}

.neogtiable-chk .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: var(--Light-Neutral-500, #6b7280);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.choose-template-section {
  position: relative;
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.choose-template-wrapper {
  position: relative;
  width: 100%;
  /* display: block; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  height: 78vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.choose-template-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.choose-template-wrapper .template-card.selected-classic .resume-type-header {
  background-color: #1575c6;
}

.choose-template-wrapper .selected-classic .resume-type-header .MuiFormControl-root .MuiFormControlLabel-label {
  color: #ffffff !important;
}

.choose-template-wrapper .selected-classic .resume-type-header .MuiRadio-root.Mui-checked {
  color: #ffffff;
}

.choose-template-wrapper .selected-accent .resume-content-header {
  background-color: #002149 !important;
}

.choose-template-wrapper .selected-accent .resume-content-header .name,
.choose-template-wrapper .selected-accent .resume-content-header .email,
.choose-template-wrapper .selected-accent .resume-content-header .mobile-no {
  color: #d2d5db;
}

.choose-template-wrapper .selected-accent .resume-content-header hr {
  border-color: #d2d5db;
}

.choose-template-wrapper .MuiPaper-root {
  border-radius: var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) 0px 0px;
  overflow: hidden;
  position: relative;
  top: 0;
  bottom: -30px;
  background: transparent;
  box-shadow: none;
}

.choose-template-wrapper .resume-type-header {
  border-radius: var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) 0px 0px;
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
  display: flex;
  padding: 4px 12px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.choose-template-wrapper .resume-type-header .MuiFormControl-root .MuiFormControlLabel-label {
  color: var(--Neutral-900, #111827);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.choose-template-wrapper .resume-content-info {
  position: relative;
  top: -30px;
  /* height: 280px; */
  border-radius: var(--Spacing-4x, 12px) !important;
  border-top: 0.5px solid var(--Neutral-200, #c5d1db);
  border-right: 0.5px solid var(--Neutral-200, #c5d1db);
  border-bottom: 1px solid var(--Neutral-200, #c5d1db);
  border-left: 0.5px solid var(--Neutral-200, #c5d1db);
  background: #fff;
  overflow: hidden;
}

.resume-content-info .resume-content-header {
  padding: 10px 12px;
}

.resume-content-info .resume-inner-info {
  padding: 10px 12px;
}

.resume-content-info .resume-content-header .name {
  color: #111827;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.resume-content-info .resume-content-header .email,
.resume-content-info .resume-content-header .mobile-no {
  color: var(--Secondary-Grey-Dark, #808080);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 140% */
}

.resume-content-info .info-txt {
  color: var(--light-neutral-900-text, #111827);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 140% */
}

.resume-content-info .resume-info-row .main-title {
  color: var(--Secondary-Grey-Dark, #808080);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 140% */
}

.resume-content-info .resume-info-row .sub-title {
  color: var(--Primary-Dark, #002149);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 140% */
}

.template-resume-display {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  top: -25px;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.template-resume-display .resume-display-container {
  position: relative;
  width: 100%;
  /* display: block; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.template-resume-display .resume-display-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.template-resume-display .header-block {
  position: relative;
  width: 100%;
  padding: 20px;
}

.template-resume-display .header-block .title {
  color: #111827;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 144.444% */
}

.template-resume-display .header-block .text {
  color: var(--Neutral-500, #6b7280);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.select-temp-btn {
  border-radius: var(--Spacing-4x, 12px) !important;
  background: var(--Primary-Light, #1575c6) !important;
  color: var(--White, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.template-resume-display .display-resume-header {
  position: relative;
  width: 100%;
  display: block;
  padding: 20px;
}

.template-resume-display .display-resume-header .name {
  color: #111827;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 162.5% */
}

.template-resume-display .display-resume-header .email,
.template-resume-display .display-resume-header .mobile-no {
  color: var(--Secondary-Grey-Dark, #808080);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.template-resume-display .display-resume-info .info-txt {
  color: var(--light-neutral-900-text, #111827);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.display-resume-info .resume-info-row .main-title {
  color: var(--Secondary-Grey-Dark, #808080);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.display-resume-info .resume-info-row .sub-title {
  color: var(--Primary-Dark, #002149);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.display-resume-info .resume-info-row ul li .info-list-txt {
  color: var(--Primary-Dark, #002149);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 150% */
}

.display-resume-info .high-skills .main-title {
  color: var(--Secondary-Grey-Dark, #808080);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  margin-bottom: 0.7rem;
}

.display-resume-info .high-skills .sub-title {
  color: var(--Primary-Dark, #002149);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.display-resume-info .high-skills .MuiChip-root {
  border-radius: 8px;
  background: rgba(42, 170, 226, 0.1);
  margin-right: 8px;
  margin-bottom: 8px;
}

.display-resume-info .high-skills .MuiChip-label {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.template-resume-display.selected-view-accent .display-resume-header {
  background: var(--Primary-Dark, #002149);
}

.template-resume-display.selected-view-accent .display-resume-header .name {
  color: #ffffff;
}

.template-resume-display.selected-view-accent .display-resume-header .email,
.template-resume-display.selected-view-accent .display-resume-header .mobile-no {
  color: #d2d5db;
}

.template-resume-display.selected-view-accent .display-resume-header hr {
  border-color: #d2d5db;
}

.full-view-resume-dialog .MuiPaper-root.MuiDialog-paper {
  background: transparent;
  box-shadow: none;
  width: 600px;
}

.full-view-resume-dialog-border-radious .MuiPaper-root.MuiDialog-paper {
  background: transparent;
  box-shadow: none;
  width: 600px;
  border-radius: 0px !important;
}

.full-view-resume-dialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.full-view-resume-dialog .MuiBackdrop-root.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.85);
}

.upload-profile-btn {
  background: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  padding: 0 !important;
}

.upload-profile-btn .material-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  height: 50px;
  border-radius: 4.8px;
  overflow: hidden;
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
}

.upload-profile-icon {
  pointer-events: none;
  width: 50px;
  height: 50px;
}

.editCustomSectionTitle .MuiInputBase-input {
  width: 100px;
  text-transform: capitalize;
}

.editCustomSectionTitle .MuiInputBase-root::before,
.editCustomSectionTitle .MuiInputBase-root::after {
  border: none;
}

.add-custom-section-wrapper .save-btn {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid var(--Primary-Light, #1575c6);
  background: var(--Primary-Light, #1575c6);
}

.add-custom-section-wrapper .save-btn svg {
  width: var(--Spacing-4x, 12px);
  height: 8.751px;
  flex-shrink: 0;
}

.add-custom-section-wrapper .save-btn:hover {
  border: 1px solid var(--Primary-Light, #1575c6);
  background: var(--Primary-Light, #1575c6);
}

.add-custom-section-wrapper .Mui-disabled.save-btn {
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.career-breaks-dialog .MuiPaper-root {
  border-radius: 16px;
  background: #fff;
}

.career-breaks-dialog .MuiCard-root .MuiCardContent-root {
  padding: var(--Spacing-6x, 20px);
}

.career-breaks-dialog .main-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.career-breaks-dialog .main-info {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.career-breaks-dialog .break-row .title {
  color: var(--Secondary-Grey-Dark, #808080);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 150% */
}

.career-breaks-dialog .break-row .break-gap-text {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.career-breaks-dialog .break-row input {
  /* color: var(--Light-Neutral-500, #6b7280); */
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.career-breaks-dialog .MuiFormControlLabel-root .MuiTypography-root {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.career-breaks-dialog .cbi-cancel-btn {
  color: var(--Primary-Light, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.career-breaks-dialog .cbi-update-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 8px var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  /* gap: var(--Spacing-4x, 12px);
  flex: 1 0 0; */
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Accent-Light, #1f9cd7);
  color: var(--White, #fff);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.career-breaks-dialog .Mui-disabled.cbi-update-btn {
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.career-breaks-dialog .career-breaks-container {
  position: relative;
  height: 400px;
  overflow-y: auto;
  padding-bottom: 100px;
}

.career-breaks-dialog .career-breaks-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.career-breaks-dialog .career-breaks-footer {
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1;
}

/* Job application page style */
.job-app-page-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: 3rem;
  background-color: #f1f5f7;
}

.job-app-page-wrapper .tab-header {
  border-bottom: 1px solid var(--Primary-700, #265073);
  background: var(--TJ-Grad, linear-gradient(90deg, #1b1464 -3.92%, #2099d6 233.8%));
  position: relative;
  width: 100%;
  padding: 0 15px;
  /* z-index: 999; */
}

.job-app-page-wrapper .tab-header .MuiTabs-scroller .MuiButtonBase-root {
  font-family: 'Inter', sans-serif;
  color: var(--Neutral-200, #c5d1db);
  font-feature-settings: 'ss01' on;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  margin-right: 40px;
}

.job-app-page-wrapper .tab-header .MuiTabs-scroller .MuiButtonBase-root.Mui-selected {
  color: var(--Shade-White, #fff);
  font-weight: 600;
}

.job-app-page-wrapper .tab-header .MuiTabs-indicator {
  background-color: #035cc9;
  height: 4px;
  border-radius: 25px;
}

.job-app-page-wrapper .job-app-page-content {
  position: relative;
  width: 100%;
}

.active-jobs-list .header-wrapper {
  position: relative;
  width: 100%;
  background-color: #f1f5f7;
  padding: 24px 12px 12px;
}

.active-jobs-list .header-wrapper .head-text {
  font-family: 'Inter', sans-serif;
  color: #111827;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 133.333% */
}

.active-jobs-list .header-wrapper .MuiIconButton-root {
  border-radius: 4px;
  border: 0.5px solid var(--Neutral-200, #9CA3AF);
  background: #fff;
  padding: 5px;
}

.active-jobs-list .active-jobs-container {
  position: relative;
  padding: 10px 15px;
  height: calc(100vh - 190px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.active-jobs-list .active-jobs-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.active-jobs-list .active-jobs-container .job-card-view {
  border-radius: var(--Spacing-4x, 12px);
  background: #fff;
  border: none;
}

.chip-job-matching {
  border-radius: 8px !important;
}

.chip-job-matching .MuiChip-label {
  /* font-family: 'Inter', sans-serif; */
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.chip-job-matching.chip-suitable {
  background: var(--Success-600, #0e7819);
}

.chip-job-matching.chip-mod-suitable {
  background: var(--Success-600, #c17229);
}

.chip-job-matching.chip-not-matching {
  background: var(--Success-600, #c12929);
}

.chip-job-matching .MuiAvatar-root {
  width: 15px;
  height: 15px;
  padding: 0.2rem;
}

.chip-job-matching.chip-suitable .MuiAvatar-root {
  background: var(--Success-50, #e7ffea);
}

.chip-job-matching.chip-mod-suitable .MuiAvatar-root,
.chip-job-matching.chip-not-matching .MuiAvatar-root {
  background: var(--Destructive-50, #ffe9e7);
}

.active-jobs-container .job-card-view .active-jobs-info {
  position: relative;
  width: 100%;
  padding-top: 1rem;
}

.active-jobs-container .job-card-view .active-jobs-info .post-title {
  display: -webkit-box;
  max-width: 240px;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  flex: 1 0;
  overflow: hidden;
  color: #111827;
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: "Inter", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.active-jobs-container .job-card-view .active-jobs-info .company-name {
  display: -webkit-box;
  max-width: 240px;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  flex: 1 0;
  overflow: hidden;
  color: #111827;
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: "Inter", sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 3px;
}

.active-jobs-container .job-card-view .active-jobs-info .work-type span {
  /* font-family: 'Inter', sans-serif; */
  color: var(--light-neutral-900-text, #111827);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.active-jobs-container .job-card-view .active-jobs-info .work-type span.city {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-jobs-container .job-card-view .active-jobs-info .work-type .MuiTypography-body1 {
  color: #c5d1db;
}

.active-jobs-container .job-card-view .active-jobs-info .posted-date {
  /* font-family: 'Inter', sans-serif; */
  color: var(--Neutral-400, #9ca3af);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.active-jobs-container .job-card-view .active-jobs-info .ctc-range {
  position: relative;
  display: flex;
  padding: var(--Vertical-Spacing-Large, 24px) 0 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.3px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
  text-align: center;
}

.active-jobs-container .job-card-view .active-jobs-info .ctc-range .text-1 {
  font-family: 'Inter', sans-serif;
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}

.active-jobs-container .job-card-view .active-jobs-info .ctc-range .text-2 {
  font-family: 'Inter', sans-serif;
  color: var(--Neutral-500, #6b7280);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.active-jobs-container .job-card-view .active-jobs-info .ctc-range .bim-tag {
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: -12px;
  border-radius: 4px;
  background: var(--Success, #29c138);
  color: var(--White, #fff);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.active-jobs-container .job-card-view.first-job-item {
  border-radius: var(--Spacing-4x, 12px);
  border-top: 0.5px solid var(--Neutral-200, #c5d1db);
  border-right: 0.5px solid var(--Neutral-200, #c5d1db);
  border-bottom: 1px solid var(--Neutral-200, #c5d1db);
  border-left: 0.5px solid var(--Neutral-200, #c5d1db);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
  border-color: #2065d1;
}

/* about job full details */

.job-full-details {
  position: relative;
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.job-full-details .job-full-details-header {
  position: sticky;
  width: 100%;
  height: auto;
  display: block;
  top: 0px;
  left: 0;
  z-index: 1;
  background: #ffffff;
  /* height: 20vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.job-full-details .job-full-details-header .header-top {
  position: relative;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--Neutral-200, #c5d1db);
  background: #fff;
}

.job-full-details .job-full-details-header .header-bottom {
  position: relative;
  width: 100%;
  padding: 20px;
}

.job-full-details .job-full-details-header .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.job-full-details .job-full-details-header .company-name {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.job-full-details .job-full-details-header svg {
  width: 12px;
  height: 12px;
}

.job-full-details .job-full-details-header .ratings .rating-count {
  color: var(--Success, #29c138);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.job-full-details .job-full-details-header .city-name {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.job-full-details .job-full-details-header .save-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  /* padding: 6px var(--Spacing-6x, 20px); */
  justify-content: center;
  align-items: center;
  /* gap: var(--Spacing-4x, 12px); */
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--CTA-Active, #1575c6);
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.job-full-details .job-full-details-header .apply-now-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  /* padding: 6px var(--Spacing-6x, 40px); */
  justify-content: center;
  align-items: center;
  /* gap: var(--Spacing-4x, 12px); */
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.job-full-details .job-full-details-header .saved-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  /* padding: 6px var(--Spacing-6x, 20px); */
  justify-content: center;
  align-items: center;
  /* gap: var(--Spacing-4x, 5px); */
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-400, #9ca3af);
  color: var(--Neutral-400, #9ca3af);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.job-full-details .job-full-details-header .saved-btn::after {
  content: 'Unsave Job';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  background-color: white;
  border-radius: var(--Spacing-4x, 12px);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.job-full-details .job-full-details-header .saved-btn:hover::after {
  opacity: 1;
}

.job-full-details .job-full-details-header .saved-btn .MuiButton-startIcon {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid var(--Neutral-400, #9ca3af);
  background: var(--Neutral-400, #9ca3af);
}

.job-full-details .job-desc-toggle-btn {
  padding: 4px 5px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
}

.job-full-details .job-desc-toggle-btn button {
  border: none;
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  padding: 5px 10px;
  text-transform: capitalize;
}

.job-full-details .job-desc-toggle-btn button.Mui-selected {
  border-radius: 8px;
  background: var(--CTA-Active, #1575c6);
  color: #ffffff;
}

.job-full-details .job-full-overview {
  position: relative;
  width: 100%;
  padding: 0 20px 20px;
  height: calc(100vh - 20vh);
  overflow-y: auto;
}

.job-full-details .job-full-overview::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.job-full-overview .job-overview-wrapper .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0.7rem;
}

.job-full-overview .job-overview-wrapper .about-post-info {
  overflow: hidden;
  color: var(--Neutral-500, #6b7280);
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.job-full-overview .job-overview-wrapper .job-overview-list li {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.job-full-overview .requirements-match-card {
  position: relative;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
  overflow: initial;
}

.job-full-overview .requirements-match-card .MuiCardContent-root {
  position: relative;
  padding: 25px 20px;
}

.job-full-overview .requirements-match-card .MuiChip-root {
  position: absolute;
  top: -12px;
  left: 15px;
}

.job-full-overview .requirements-match-card .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.job-full-overview .requirements-match-card .info-text {
  color: #808080;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  margin-top: -2px;
}

.match-icon {
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--Success, #29c138);
}

.match-icon svg {
  width: 8px;
  height: auto;
  flex-shrink: 0;
}

.warning-icon {
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--Warning, #c17229);
}

.warning-icon svg {
  width: auot;
  height: 10px;
  flex-shrink: 0;
}

.error-match-icon {
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--Destructive, #c12929);
}

.error-match-icon svg {
  width: 8px;
  height: auto;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}



.skills-match-card .match-icon svg {
  width: 6px !important;
  height: auto !important;
}

.skills-match-card .error-match-icon svg {
  width: 6px !important;
  height: auto !important;
}


.selected-chip-icon {
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--Neutral-200, #ffffff);
}

.selected-chip-icon svg {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
}

.job-full-overview .requirements-match-card .requirements-match .req-title {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.job-full-overview .requirements-match-card .requirements-match .req-text {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}
.info-note.info-warning {
  border: 0.5px solid var(--Warning, #C17229);
  padding: 8px 12px;
}
.info-note.info-note.info-warning .note-txt {
  color: var(--Warning, #C17229);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.upload-photo-stack-wrapper {
  align-items: flex-start!important;
}
.perview-resume {
  display: inline-flex;
  width: 80px;
  height: 80px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  border-radius: 12px;
  border: 1px dashed #000;
}
.job-full-overview .requirements-match-card .preferences-match .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.job-full-overview .requirements-match-card .pref-info-text {
  color: #808080;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.job-full-overview .requirements-match-card .pref-info-text a {
  color: var(--CTA-Active, #1575c6);
}

.job-full-overview .requirements-match-card .preferences-match .pref-match-title {
  color: var(--Neutral-900, #111827);
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.job-full-overview .requirements-match-card .preferences-match .pref-match-info {
  color: #111827;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.job-full-overview .requirements-match-card .preferences-match .notice-period-error {
  color: #c12929 !important;
}

.job-full-overview .requirements-match-card .preferences-match .bim-tag {
  display: inline-flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Success, #29c138);
  color: var(--White, #fff);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  padding-right: 0;
  padding-left: 0;
}

.job-full-overview .skills-match-card {
  position: relative;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--Neutral-50, #f9faff);
  overflow: initial;
}

.job-full-overview .skills-match-card .MuiCardContent-root {
  position: relative;
  padding: 20px;
  padding-bottom: 0px;
}

.job-full-details .skills-match-card .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.job-full-details .skills-match-card .info-text {
  margin-bottom: 20px;
  color: #808080;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.job-full-details .skills-match-card .update-skills-link {
  color: var(--CTA-Active, #1575c6);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-decoration-line: underline;
}

.job-full-details .skills-match-card .MuiChip-root {
  position: relative;
  border-radius: 8px;
  border: 0.5px solid var(--Success-600, #0E7819);
  background: rgba(231, 255, 234, 0.00);
  padding: 4px 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  height: 25px;
}

.job-full-details .skills-match-card .MuiChip-root .MuiChip-label .star-icon {
  padding-right: 0;
  /* margin-left: -7px; */
  margin-bottom: 2px;
  padding-left: 2px;
}

.job-full-details .skills-match-card .MuiChip-root .MuiChip-icon {
  height: 12px;
  width: 12px;
  position: absolute;
  right: 0;
  bottom: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.job-full-details .skills-match-card .MuiChip-root.Mui-disabled {
  border: 0.5px solid var(--Neutral-500, #6b7280);
  background: var(--Neutral-50, #f9faff);
}

.job-full-details .skills-match-card .MuiChip-root .MuiChip-icon.error-match-icon {
  background: var(--Neutral-200, #c5d1db);
}

.job-full-details .skills-match-card .MuiChip-root .MuiChip-icon.error-match-icon svg path {
  fill: var(--Neutral-500, #6b7280);
}

.job-full-details .job-description {
  margin-bottom: calc(100vh - 30vh);
}

.job-full-details .job-description .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0.7rem;
}

.job-full-details .job-description .info-text {
  overflow: hidden;
  color: var(--Neutral-500, #6b7280);
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.job-full-details .job-description .info-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-full-details .job-description .job-desc-list li {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.12px;
}

/* My jobs tabs style */
.my-jobs-tabs {
  position: relative;
  width: 100%;
  padding: 12px 0 0;
}

.my-jobs-tabs .MuiButtonBase-root {
  color: #111827;
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  width: 50%;
}

.my-jobs-tabs .MuiButtonBase-root.Mui-selected {
  color: #111827;
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.my-jobs-tabs .MuiTabs-flexContainer {
  border-bottom: 6px solid #ffffff;
}

.my-jobs-tabs .MuiTabs-indicator {
  height: 6px;
  background-color: #4d71ff;
}

.job-application .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0.3rem;
}

.job-application .info-text {
  overflow: hidden;
  color: var(--Neutral-500, #111827);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.job-application .job-application-list .questions-text {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.job-application .job-application-list .answer-text {
  color: var(--Neutral-700, #374151);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.job-application .job-application-list li {
  position: relative;
  margin-bottom: 1rem;
}

.job-application .job-application-list li::marker {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.applied-resume-date-info {
  position: relative;
  width: 100%;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Accent-Neutral, #cce0f4);
  padding: var(--Spacing-4x, 12px) 16px;
}

.applied-resume-date-info .info-text {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.applied-resume-date-info a {
  color: var(--CTA-Active, #1575c6);
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
  text-decoration: none;
}

.job-app-drawer-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.job-app-drawer-container .drawer-header {
  position: relative;
  padding: var(--Spacing-6x, 20px) 10px;
  background: var(--TJ-Grad, linear-gradient(90deg, #1b1464 -3.92%, #2099d6 233.8%));
}

.job-app-drawer-container .drawer-header .title {
  color: var(--White, #fff);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 144.444% */
  margin-bottom: 0.5rem;
}

.job-app-drawer-container .drawer-header .sub-text {
  color: var(--Neutral-200, #c5d1db);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.job-app-drawer-container .drawer-content {
  position: relative;
  padding: 25px 20px;
  background-color: #ffffff;
  height: 65vh;
  overflow-y: scroll;
}

.job-app-drawer-container .drawer-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.job-app-drawer-container .drawer-content .current-job-details .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  margin-bottom: 0.5rem;
}

.job-app-drawer-container .drawer-content .current-job-details .sub-text {
  color: var(--Light-Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.job-app-drawer-container .drawer-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--Neutral-50, #f9faff);
  background: var(--White, #fff);
  box-shadow: 0px -4px 54px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  z-index: 1;
}

.job-app-drawer-container .drawer-footer .cancel-btn {
  color: var(--Primary-Light, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.job-app-drawer-container .drawer-footer .update-btn {
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.job-app-drawer-container .drawer-footer .update-btn.Mui-disabled {
  background: var(--Accent-Neutral, #cce0f4);
}

.update-skills-drawer-container .update-skills-content .skills-section .title {
  color: var(--Neutral-500, #6b7280);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0.5rem;
}

.update-skills-drawer-container .select-relevent-skills .MuiFormControlLabel-root {
  border: 0.5px solid var(--Neutral-500, #6B7280);
  border-radius: 8px;
  padding: 0.2rem 0.5rem;
  margin-left: -10px;
  margin-bottom: 0.5rem;
}

.update-skills-drawer-container .select-relevent-skills .MuiRadio-root {
  display: none;
}

.update-skills-drawer-container .select-relevent-skills .MuiFormControlLabel-root .MuiTypography-root {
  font-family: "Inter", sans-serif;
  color: var(--Neutral-500, #6B7280);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.update-skills-drawer-container .select-relevent-skills .MuiFormControlLabel-root.selected-skill {
  border-color: var(--CTA-Active, #1575C6) !important;
}

.update-skills-drawer-container .select-relevent-skills .MuiFormControlLabel-root.selected-skill .MuiTypography-root {
  color: var(--CTA-Active, #1575C6);
  font-weight: 600;
}

.update-skills-drawer-container .update-skills-content .MuiFormControlLabel-root .MuiTypography-root {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.update-skills-drawer-container .update-skills-content .MuiFormControlLabel-root.Mui-disabled .MuiTypography-root {
  opacity: 0.6;
}

/* chat bot style*/
.chat-bot-drawer-dialog .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
}

.chat-bot-drawer-container .drawer-content {
  position: relative;
  padding: 0 20px 6rem;
  background-color: #ffffff;
  height: 85vh !important;
  overflow: hidden;
}

.chat-bot-drawer-container .chat-content-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.chat-bot-drawer-container .chat-content-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.chat-bot-drawer-container .chat-content-container .chat-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  overflow-y: auto;
}

.chat-bot-drawer-container .chat-content-container .chat-inner-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.chat-bot-drawer-container .drawer-content .chat-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.chat-bot-drawer-container .chat-bot-spalsh {
  width: 144.2px;
  height: 163.047px;
  flex-shrink: 0;
}

.chat-loader {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 16px;
  aspect-ratio: 2.5;
  --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: l43 1s infinite linear;
}

@keyframes l43 {
  0% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }

  16.67% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }

  33.33% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 0, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }

  50% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 100%, calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 50%;
  }

  66.67% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 0;
  }

  83.33% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 100%;
  }

  100% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
}

.chat-bot-drawer-container .chat-footer .chat-footer-cta {
  border-top: 1px solid var(--Neutral-50, #f9faff);
  background: var(--White, #fff);
  box-shadow: 0px -4px 54px 0px rgba(0, 0, 0, 0.16);
  padding: var(--Spacing-6x, 20px);
}

.chat-bot-drawer-container .chat-footer input {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.chat-bot-drawer-container .chat-footer .send-msg-btn {
  border-radius: 8px;
  background: var(--CTA-Active, #1575c6);
}

.chat-bot-drawer-container .chat-footer .send-msg-btn.Mui-disabled {
  background: var(--Accent-Neutral, #cce0f4);
}

.chat-bot-drawer-container .chat-row.message-in .jn-chat-icon {
  width: var(--Vertical-Spacing-Large, 24px);
  height: 27.137px;
  flex-shrink: 0;
}

.chat-bot-drawer-container .chat-row.message-in .message-in-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 240px;
  max-width: 240px;
  padding: 8px;  
  gap: 10px;
  border-radius: 0px 16px 16px 16px;
  border: 0.5px solid var(--Neutral-200, #C5D1DB);
  background: var(--Neutral-50, #F9FAFF);
}

.chat-bot-drawer-container .chat-row.message-in .message-in-text-container .msg-text {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  align-self: stretch;
}
.chat-bot-drawer-container .chat-row.message-in .message-in-text-container .msg-text.ctc-amt,
.chat-bot-drawer-container .chat-row.message-out .message-out-text-container .msg-text.ctc-amt {
  font-size: 20px;
  font-weight: 600;
}
.chat-bot-drawer-container .chat-row.message-in .MuiLinearProgress-root {
  background-color: #c5d1db;
}

.chat-bot-drawer-container .chat-row.message-out .message-out-text-container {
  display: flex;
  width: 240px;
  max-width: 240px;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 16px 0px 16px 16px;
  border: 0.5px solid var(--Neutral-200, #c5d1db);
  background: var(--Accent-Neutral, #cce0f4);
}

.chat-bot-drawer-container .chat-row.message-out .message-out-text-container .msg-text {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  align-self: stretch;
}

.chat-bot-drawer-container .chat-row.message-out .selected-resume-wrapper {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-50, #f9faff);
}

.chat-bot-drawer-container .chat-row.message-out .selected-resume-wrapper img {
  width: 109px;
  height: 155px;
  border-radius: 8px;
  border: 0.5px solid var(--Neutral-200, #c5d1db);
}

.select-resume-option-container {
  position: relative;
  width: 270px;
}

.select-resume-option-container .slick-list .slick-track {
  display: flex;
  /* width: 240px!important; */
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.select-resume-option-container .slick-track .resume-option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -50px;
}

.select-resume-option-container .slick-track .resume-option img {
  width: 100%;
  height: 155px;
  border-radius: 8px;
  border: 0.5px solid var(--Neutral-200, #c5d1db);
  /* background: url(<path-to-image>) lightgray 0px -0.455px / 100% 219.993% no-repeat; */
}

.select-resume-option-container .slick-track .resume-option .resume-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  background: var(--CTA-Active, #1575c6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.select-resume-option-container .slick-track .resume-option .resume-title p {
  color: var(--White, #fff);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.select-resume-option-container .slick-prev {
  position: absolute;
  left: 0;
  z-index: 1;
}

.select-resume-option-container .slick-next {
  position: absolute;
  right: 0;
  z-index: 1;
}

.select-resume-option-container .slick-next:before,
.select-resume-option-container .slick-prev:before {
  color: #1575c6;
}

.select-resume-option-container .resume-option .select-resume-action {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
}

.select-resume-option-container .resume-option:hover .select-resume-action {
  opacity: 0.5;
}

.select-resumes-dialog .MuiDialog-paper {
  background: none;
  box-shadow: none;
  overflow: hidden;
}

.select-resumes-dialog .resume-options-slider-container .slick-slider {
  padding: 3rem 0;
}

.select-resumes-dialog .resume-options-slider-container .slick-slider .slick-list .resume-options-preview {
  position: relative;
  width: 100%;
  height: 65vh;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 12px;
}

.select-resumes-dialog .resume-options-slider-container .slick-slider .slick-list .resume-options-preview::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.select-resumes-dialog .slick-slider .slick-dots {
  bottom: 15px;
}

.select-resumes-dialog .resume-options-slider-container .resume-option-item {
  position: relative;
  display: block;
  padding: 0 4.5rem;
}

.select-resumes-dialog .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.select-resumes-dialog .slick-slider .slick-dots li button:before {
  font-size: 12px;
  color: #d9d9d9;
  opacity: 1;
}

.select-resumes-dialog .slick-slider .slick-dots li.slick-active button:before {
  color: #1f9cd7;
}

.select-resumes-dialog .resume-option-item .header-update-select-resume {
  position: relative;
  padding: 16px 20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: var(--Neutral-50, #f9faff);
}

.select-resumes-dialog .resume-option-item .header-update-select-resume::after {
  content: '';
  background: var(--Neutral-50, #f9faff);
  width: 100%;
  height: 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.select-resumes-dialog .resume-option-item .header-update-select-resume .resume-title {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.select-resumes-dialog .resume-option-item .header-update-select-resume .last-updated {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.select-resumes-dialog .resume-option-item .header-update-select-resume .update-btn {
  display: flex;
  padding: 8px var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--CTA-Active, #1575c6);
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
}

.select-resumes-dialog .resume-option-item .header-update-select-resume .select-resume-btn {
  display: flex;
  padding: 8px var(--Spacing-4x, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
}

.select-resumes-dialog .slick-slider .slick-next {
  right: 15px;
  z-index: 1;
}

.select-resumes-dialog .slick-slider .slick-prev {
  left: 15px;
  z-index: 1;
}

.select-resumes-dialog .slick-slider .slick-next:before,
.select-resumes-dialog .slick-slider .slick-prev:before {
  font-size: 34px;  
  color: #1575c6;
}

.create-new-resume-slide {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  gap: -32px;
  align-self: stretch;
}

.create-new-resume-slide .inner-container {
  display: flex;
  padding: var(--Card-Bg-Height, 48px) var(--Spacing-6x, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Neutral-50, #f9faff);
}

.create-new-resume-slide .inner-container .MuiSvgIcon-root {
  width: 73.299px;
  height: 87.607px;
}

.create-new-resume-slide .inner-container p {
  color: var(--CTA-Active, #1575c6);
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.auto-save-app-dialog .MuiPaper-root {
  width: 510px;
}

.auto-save-app-dialog .MuiPaper-root {
  border-radius: 16px;
  background: #fff;
}

.auto-save-app-dialog .MuiCard-root .MuiCardContent-root,
.auto-save-app-dialog .MuiCard-root .MuiCardActions-root {
  padding: var(--Spacing-6x, 20px);
}

.auto-save-app-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
  margin-bottom: 0.5rem;
}

.auto-save-app-dialog .info-text {
  color: var(--Light-Neutral-600, #4b5563);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.auto-save-app-dialog .MuiButton-root {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--CTA-Active, #1575c6);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.auto-save-app-dialog .external-job .MuiButton-root {
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--CTA-Active, #1575c6);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

/* My Resume Container */

.resume-type-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
}

.resume-type-container .resume-type-content {
  position: relative;
  padding: 30px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 180px);
}

.resume-type-container .resume-type-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.resume-type-container .resume-type-header .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 144.444% */
}

.resume-type-container .resume-type-card {
  position: relative;
  border-radius: var(--Spacing-4x, 12px);
  background: #fff;
  border: none;
  cursor: pointer;
}

.resume-type-container .resume-type-card .MuiCardContent-root {
  position: relative;
  padding: 16px;
}

.resume-type-container .resume-type-card .card-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
  margin-bottom: 0.5rem;
  text-align: left;
}

.resume-type-container .resume-type-card .last-updated {
  color: var(--Neutral-400, #9ca3af);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-align: left;
}

.resume-type-container .resume-type-card.selected-card {
  border-radius: var(--Spacing-4x, 12px);
  border-top: 0.5px solid var(--Neutral-200, #c5d1db);
  border-right: 0.5px solid var(--Neutral-200, #c5d1db);
  border-bottom: 1px solid var(--Neutral-200, #c5d1db);
  border-left: 0.5px solid var(--Neutral-200, #c5d1db);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  transform: scale(1.1);
}

.resume-type-container .resume-type-card.master-resume-card {
  overflow: inherit !important;
}

.resume-type-container .resume-type-card.master-resume-card .MuiCardContent-root {
  padding: 30px 16px 16px;
}

.resume-type-container .resume-type-card.master-resume-card .suggestion-tag {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  position: absolute;
  left: 10px;
  top: -11px;
  border-radius: 8.377px;
  background: var(--Success-600, #0e7819);
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.resume-type-container .create-new-resume-btn {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  color: var(--CTA-Active, #1575C6);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
  border-radius: var(--Spacing-4x, 12px);
  background: #fff;
  border: none;
  text-transform: capitalize;
  margin: 0 auto;
}

.resume-type-container .create-new-resume-btn:hover {
  background-color: #ffffff;
  border: none;
}

.resume-type-container .create-new-resume-btn.Mui-disabled {
  color: var(--Neutral-400, #9CA3AF) !important;
}

.resume-type-container .total-resume-text {
  color: var(--Destructive, #c12929);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.my-resume-details {
  position: relative;
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.my-resume-details .my-resume-details-header .header-top {
  position: relative;
  width: 100%;
  padding: 20px;
  background: #fff;
}

.my-resume-details .my-resume-details-header .header-bottom {
  position: relative;
  width: 100%;
  padding: 20px;
}

.my-resume-details .my-resume-details-header .resume-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.my-resume-details .my-resume-details-header .last-updated-date {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.my-resume-details .my-resume-details-header .save-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 6px var(--Spacing-6x, 60px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--CTA-Active, #1575c6);
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.my-resume-details .my-resume-details-header .apply-now-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 6px var(--Spacing-6x, 40px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.download-resume-menu .MuiMenu-paper .MuiMenu-list li,
.resume-action-menu .MuiMenu-paper .MuiMenu-list li {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.job-action-menu .MuiMenu-paper .MuiMenu-list li {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.resume-action-menu-btn {
  padding: 0 8px !important;
  width: 25px;
  height: 25px;
}

.resume-action-menu-btn[aria-expanded='true'] {
  border-radius: 4px;
  background: var(--Primary-Light, #1575c6);
  color: #ffffff;
}

.resume-action-menu-btn[aria-expanded='true'] svg path {
  fill: #ffffff;
}

.job-action-menu-btn {
  padding: 0 8px !important;
  width: 25px;
  height: 25px;
}

.job-action-menu-btn[aria-expanded='true'] {
  border-radius: 4px;
  background: var(--Primary-Light, #1575c6);
  color: #ffffff;
}

.job-action-menu-btn[aria-expanded='true'] svg path {
  fill: #ffffff;
}

.resume-action-menu .MuiMenu-paper .MuiMenu-list li.Mui-disabled .MuiListItemIcon-root {
  background: var(--Neutral-100, #e7ebfd);
}

.resume-action-menu .MuiMenu-paper .MuiMenu-list li.Mui-disabled .MuiListItemIcon-root svg path {
  fill: rgba(55, 65, 81, 0.4);
}

.resume-action-menu .MuiMenu-list li .MuiListItemIcon-root {
  display: flex;
  justify-content: center;
  border-radius: 3.2px;
  background: rgba(42, 170, 226, 0.2);
  min-width: 23px;
  padding: 0.2rem;
  margin-right: 0.5rem;
}

.my-resume-details .my-resume-overview {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.my-resume-details .my-resume-overview .overview-container {
  position: relative;
  width: 100%;
  height: 68vh;
  overflow-y: auto;
}

.my-resume-details .my-resume-overview .overview-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.my-resume-details .my-resume-details-card {
  position: relative;
  width: 100%;
  border-radius: var(--Spacing-6x, 20px);
  border-top: 1px solid var(--Neutral-200, #c5d1db);
  background: #fff;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.my-resume-details .my-resume-overview .candidate-name {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 162.5% */
}

.my-resume-details .my-resume-overview .candidate-email-contact {
  color: var(--Neutral-700, #374151);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.my-resume-details .my-resume-overview .candidate-summary {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.my-resume-overview .candi-personal-det-row .title {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0.5rem;
}

.my-resume-overview .education-row .school-uni-name {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.my-resume-overview .candi-personal-det-row .edu-degree-type span {
  font-family: 'Inter', sans-serif;
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.my-resume-overview .candi-personal-det-row .edu-degree-type .MuiTypography-body1 {
  color: #374151;
}

.my-resume-overview .education-row .year-of-passout,
.my-resume-overview .certifi-row .year-of-passout {
  color: var(--Neutral-700, #374151);
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 150% */
}

.my-resume-overview .personal-edu-text .educational-info-txt,
.my-resume-overview .personal-work-text .work-info-txt {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.my-resume-overview .certifi-row .university-name {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.my-resume-overview .work-exp .post-name {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.my-resume-overview .candi-personal-det-row .exp-company-info span {
  font-family: 'Inter', sans-serif;
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.my-resume-overview .candi-personal-det-row .exp-company-info .MuiTypography-body1 {
  color: #374151;
}

.my-resume-overview .work-exp .working-year {
  color: var(--Neutral-700, #374151);
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 150% */
}

.my-resume-overview .highlighted-skills .title {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 1rem;
}

.my-resume-overview .highlighted-skills .header-block {
  border-radius: 8px 8px 0px 0px;
  background: var(--Neutral-50, #f9faff);
  padding: 4px 8px;
}

.my-resume-overview .highlighted-skills .header-block .MuiTypography-root {
  display: inline-block;
  color: var(--Neutral-500, #6b7280);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
}

.my-resume-overview .highlighted-skills .text-block .MuiTypography-root {
  display: inline-block;
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}

/* Create Update Resume Style */
.create-update-resume-wrapper {
  position: relative;
  width: 100%;
  background-color: #F1F5F7;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.create-update-resume-wrapper .header-section .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* text-transform: capitalize; */
  line-height: 24px;
  /* 133.333% */
}

.create-update-resume-wrapper .header-section .info-text {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.create-update-resume-wrapper .header-section .save-btn {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid var(--Primary-Light, #1575c6);
  background: var(--Primary-Light, #1575c6);
}

.create-update-resume-wrapper .header-section .save-btn svg {
  width: var(--Spacing-4x, 12px);
  height: 8.751px;
  flex-shrink: 0;
}

.create-update-resume-wrapper .header-section .save-btn:hover {
  border: 1px solid var(--Primary-Light, #1575c6);
  background: var(--Primary-Light, #1575c6);
}

.create-update-resume-wrapper .create-update-resume-card {
  position: relative;
  width: 100%;
  border-radius: var(--Spacing-6x, 20px);
  border: none;
  background: #fff;
  box-shadow: none;
}

.create-update-resume-wrapper .candidate-personal-info .candidate-name {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.create-update-resume-wrapper .candidate-personal-info .candidate-email-contact {
  color: var(--Neutral-700, #374151);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.create-update-resume-wrapper .candidate-personal-info textarea {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  height: auto;
}

.create-update-resume-wrapper .resume-details-row .MuiPaper-root.MuiAccordion-root {
  box-shadow: none;
}

.create-update-resume-wrapper .resume-details-row .MuiPaper-root.MuiAccordion-root::before {
  display: none;
}

.create-update-resume-wrapper .resume-details-row .title {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.create-update-resume-wrapper .resume-details-row .add-new-link {
  color: var(--CTA-Active, #1575c6);
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.create-update-resume-wrapper .resume-details-row .MuiAccordionSummary-content {
  margin: 0;
  justify-content: space-between;
}

.create-update-resume-wrapper .resume-details-row .MuiButtonBase-root {
  min-height: 30px;
}

.scroll-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.scroll-container .scroll-wrapper {
  position: relative;
  width: 100%;
  height: 65vh;
  overflow-y: auto;
}

.scroll-container .scroll-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.create-update-resume-wrapper .resume-details-row.education-sec .info-chip {
  display: flex;
  padding: 4px 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Accent-Neutral, #cce0f4);
}

.create-update-resume-wrapper .resume-details-row.education-sec .MuiChip-root .MuiChip-label {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.create-update-resume-wrapper .resume-details-row .block-title {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.create-update-resume-wrapper .resume-details-row .update-link {
  color: var(--CTA-Active, #1575c6);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  text-decoration: none;
}

.create-update-resume-wrapper .resume-details-row .delete-btn-icon,
.create-update-resume-wrapper .resume-details-row .delete-btn-icon:hover {
  border-radius: 4px;
  background: #fee;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-height: 24px;
}

.create-update-resume-wrapper .resume-details-row .candidate-short-info-list span {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.create-update-resume-wrapper .resume-details-row .skills-block .MuiChip-root {
  border-radius: 8px;
  background: rgba(42, 170, 226, 0.1);
  margin-right: 8px;
  margin-bottom: 8px;
}

.create-update-resume-wrapper .resume-details-row .skills-block .MuiChip-label {
  color: var(--Primary-Dark, #002149);
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.skills-block .add-more-skills-link {
  color: var(--Primary-Light, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  text-decoration: none;
}

.resume-details-row .block-info-text .MuiPaper-root {
  color: var(--Primary-Dark, #002149);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  display: flex;
  padding: var(--Spacing-4x, 12px);
  align-items: flex-start;
  gap: var(--Spacing-4x, 12px);
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-300, #d2d5db);
  background: #fff;
  text-align: left;
}

.action-dialog-card .MuiDialog-paper {
  position: relative;
  width: 550px;
  border-radius: var(--Spacing-6x, 20px);
  background: var(--Shade-White, #fff);
}

.action-dialog-card .MuiDialog-paper .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.action-dialog-card .MuiDialog-paper .info-text {
  color: var(--Light-Neutral-600, #4b5563);
  text-align: center;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.action-dialog-card .MuiButton-outlined {
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  text-transform: capitalize;
}
.action-dialog-card .MuiButton-outlined.Mui-disabled{
  color: rgba(145, 158, 171, 0.8);
  background: rgba(145, 158, 171, 0.24);
  border: rgba(145, 158, 171, 0.24);
}

.action-dialog-card .MuiButton-contained {
  color: var(--Shade-White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  padding: 12px 20px;
  border-radius: 12px;
  background: var(--CTA-Active, #1575c6);
  text-transform: capitalize;
}

/* .dashboard-header-custome {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
} */
/* Job application dashboard style */

.track-job-applications {
  position: relative;
  width: 80%;
  height: auto;
  /* padding: 40px 0; */
  background-color: #ffffff;
}

.track-job-applications .track-app-card-block {
  position: relative;
  width: 100%;
  padding: 0 40px;
}

.dashboard-content-wrapper .candidate-name {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.track-job-applications .text-main {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.track-job-applications .text-sub {
  color: var(--Neutral-500, #6b7280);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.track-job-applications .card-wrapper .current-track-status-chip {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #f1ecff;
  color: var(--Accent-Dark, #3d1c9b);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
  height: 20px;
}

.track-job-applications .card-wrapper .current-track-status-chip .MuiChip-label {
  padding: 0;
}

.track-job-applications .card-wrapper .track-status-slider {
  padding: 0;
  color: #e7ebfd;
}

.track-job-applications .card-wrapper .track-status-slider .MuiSlider-mark {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.track-job-applications .card-wrapper .track-status-slider .MuiSlider-markActive {
  background-color: #29c138;
}

.track-job-applications .card-wrapper .track-status-slider .MuiSlider-track {
  color: #29c138;
}

.track-job-applications .card-wrapper .card-content-bottom.track-status-block {
  background-color: #3d1c9b;
}

.dashboard-page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.dashboard-page-wrapper .dashboard-content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.dashboard-page-wrapper .dashboard-content-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.dashboard-page-wrapper .dashboard-card-row {
  /* display: flex; */
  align-items: center;
  gap: var(--Vertical-Spacing-Large, 24px);
  align-self: stretch;
  position: relative;
  padding-bottom: 1.5rem;
  width: 1035px;
}

.dashboard-page-wrapper .card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 190px;
  cursor: pointer;
}

.dashboard-page-wrapper .card-wrapper .card-content-top {
  display: flex;
  width: 308px;
  padding: var(--Spacing-6x, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 1px solid var(--Neutral-100, #e7ebfd);
  border-right: 1px solid var(--Neutral-100, #e7ebfd);
  border-left: 1px solid var(--Neutral-100, #e7ebfd);
  background: var(--White, #fff);
  position: relative;
  z-index: 2;
}

.dashboard-page-wrapper .card-wrapper .card-content-bottom {
  display: flex;
  width: 308px;
  padding: 40px 20px 12px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  /* border-radius: 12px; */
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--Primary-Light, #1575c6);
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.custom-card-bottom-margin {
  margin-bottom: 20px !important;
}

.dashboard-page-wrapper .card-wrapper .card-content-top .job-title {
  display: -webkit-box;
  max-width: 240px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: #111827;
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: 'Inter', sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.dashboard-page-wrapper .card-wrapper .card-content-top .job-company {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--light-neutral-900-text, #111827);
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-overflow: ellipsis;
  max-width: 250px;
}

.dashboard-page-wrapper .card-wrapper .card-content-top .applied-days {
  color: var(--light-neutral-900-text, #111827);
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.dashboard-page-wrapper .card-wrapper .card-content-bottom button {
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
  padding: 0;
}

.dashboard-page-wrapper .card-wrapper .arrow-btn {
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
}

.dashboard-page-wrapper .card-wrapper:hover .arrow-btn {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.update-profile-section {
  display: flex;
  width: 320px;
  height: 377px;
  padding: 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: var(--Warning-50, #fff6e7);
  position: absolute;
  top: -120px;
  right: 0;
  z-index: 2;
}

.update-profile-section .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.update-profile-section .info-text {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.update-profile-section .resume-update-msg {
  padding: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid #ffe1ae;
  background: #fff;
  color: var(--Neutral-900, #111827);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.update-profile-section .update-now-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 8px var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.update-profile-section .cancel-update-link {
  color: var(--Primary-Light, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.explore-new-opp {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
}

.explore-new-opp .explore-new-opp-container {
  position: relative;
  width: 100%;
  display: block;
  padding: 40px;
  /* background-color: #ffffff; */
}

.explore-new-opp .explore-new-opp-container.bg-light-blue {
  position: relative;
  width: 100%;
  display: block;
  padding: 40px;
  background-color: #CCE0F4;
}

/* .first-time-user .explore-new-opp .explore-new-opp-container {
  background-color: #ffffff;
} */

.explore-new-opp .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

/*
.explore-new-opp::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #CCE0F4;
} 
*/
/* .first-time-user .explore-new-opp .explore-new-opp-container {
  background-color: #ffffff;
} */
.explore-new-opp .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
}

/* .explore-new-opp .MuiToggleButton-root {
  border-radius: 8px;
} */
.explore-new-opp .MuiToggleButton-root.Mui-selected,
.explore-new-opp .MuiToggleButton-root.Mui-selected:hover {
  background-color: #1575c6;
  color: #ffffff;
  /* border-radius: 8px; */
  border: none;
}

.explore-new-opp .jobs-toggle-btn button {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
  background-color: #ffffff;
}

.explore-new-opp .text-1 {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  /* font-family: 'Inter', sans-serif; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.explore-new-opp .text-2 {
  color: var(--Neutral-700, #374151);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.explore-new-opp .view-all-btn {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.explore-new-opp .card-wrapper .work-type span {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.explore-new-opp .card-wrapper .work-type p {
  color: #c5d1db;
}

.explore-new-opp .card-wrapper .work-type span.city {
  display: inline-block;
  max-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.explore-new-opp .card-wrapper .package {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}
.info-note {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: var(--Warning-50, #FFF6E7);
  margin: 20px 0;
}
.info-note .note-txt {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 192.857% */
}

.total-work-exp {
  display: flex;
  padding: 12px;
  border-radius: 12px;
  background: var(--Accent-Neutral, #CCE0F4);
  margin-top: 24px;
}
.total-work-exp .info-txt {
  color: var(--Light-Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.total-work-exp .count-years {
  display: flex;
  padding: 8px;
  border-radius: 6px;
  border: 0.5px solid var(--CTA-Active, #1575C6);
  background: #FFF;
}
.total-work-exp .count-years .count {
  color: var(--CTA-Active, #1575C6);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.total-work-exp .count-years .year-txt {
  color: var(--Neutral-400, #9CA3AF);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 175% */
}
.explore-new-opp .card-wrapper .package-type {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}
.chat-bot-drawer-container .selected-resume-wrapper:hover .change-resume-btn {
  opacity: 1;
}
.chat-bot-drawer-container .chat-row.message-in .message-in-text-container .update-link {
  color: var(--CTA-Active, #1575C6);
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
}
.chat-bot-drawer-container .chat-bot-continue-app-btn {
  display: inline-block;
  color: #1575C6;
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  height: var(--Card-Bg-Height, 48px);
  padding: 8px var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  flex: 1 0;
  border-radius: 8px;
  border: 0.5px solid var(--CTA-Active, #1575C6);
  background: var(--White, #FFF);
  text-transform: uppercase;
  width: 240px;
  margin-left: 2rem;
}
.explore-new-opp .card-wrapper .bim-tag {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  background: var(--Success, #29c138);
  color: var(--White, #fff);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.explore-new-opp .card-wrapper .posted-date {
  color: var(--light-neutral-900-text, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.explore-new-opp .card-wrapper .chip-job-matching {
  position: absolute;
  top: -12px;
}

.explore-new-opp .card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 250px;
}

.explore-new-opp .slick-slider .slick-list {
  padding-top: 2rem;
  margin: 0 7px 0 25px;
}

.explore-new-opp .slick-slider button.slick-arrow {
  display: flex !important;
  width: var(--Card-Bg-Height, 48px);
  height: var(--Card-Bg-Height, 48px);
  padding: var(--Spacing-4x, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-6x, 20px);
  border-radius: 16px;
  border: 1px solid var(--Light-Neutral-200, #ECEDF3);
  background: var(--Shade-White, #FFF) !important;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.explore-new-opp .slick-slider button.slick-next::before,
.explore-new-opp .slick-slider button.slick-prev::before {
  display: none !important;
}

.explore-new-opp .slick-slider button.slick-next {
  background: #ffffff url('../public/assets/arrow-next-png.png') center center no-repeat !important;
  background-size: 30px !important;
  right: 5px;
}

.explore-new-opp .slick-slider button.slick-prev {
  background: #ffffff url('../public/assets/arrow-prev-png.png') center center no-repeat !important;
  background-size: 30px !important;
  left: -25px;
  z-index: 1;
}

.job-track-menu-list .MuiPopover-paper {
  border: 1px solid var(--Neutral-200, #c5d1db);
  background: var(--White, #fff);
  border-radius: 8px !important;
}

.job-track-menu-list .track-list li .MuiListItemIcon-root {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 0;
  margin-right: 0.5rem;
  border-radius: 30px;
  background: var(--Neutral-100, #e7ebfd);
}

.job-track-menu-list .track-list li .MuiListItemIcon-root span {
  color: var(--Neutral-700, #374151);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
}

.job-track-menu-list .track-list .MuiListItemText-root .MuiTypography-root {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
}

.job-track-menu-list .track-list li.process-in-review .MuiListItemIcon-root {
  background: var(--Accent-Dark, #3d1c9b);
}

.job-track-menu-list .track-list li.process-in-review .MuiListItemIcon-root .MuiTypography-root {
  color: #ffffff;
}

.job-track-menu-list .track-list li.process-in-review .MuiListItemText-root .MuiTypography-root {
  color: var(--Accent-Dark, #3d1c9b);
}

.job-track-menu-list .track-list li.process-completed .MuiListItemIcon-root {
  background: var(--Success-50, #e7ffea);
}

.job-track-menu-list .track-list li.process-completed .MuiListItemText-root .MuiTypography-root {
  color: #0e7819;
}

.supercharge-your-career {
  position: relative;
  width: 100%;
  display: block;
}

.supercharge-your-career .supercharge-career-container {
  position: relative;
  width: 100%;
  padding: 40px;
  background-color: #ffffff;
}

.supercharge-your-career .supercharge-career-container.bg-light-blue {
  background-color: #CCE0F4;
}

.supercharge-your-career .supercharge-inner-container {
  position: relative;
  width: 100%;
}

.supercharge-your-career .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
}

.supercharge-your-career .info-text {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  /* 150% */
}

.supercharge-your-career .career-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Vertical-Spacing-Large, 24px);
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-right: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-bottom: 1px solid var(--Neutral-200, #C5D1DB);
  border-left: 0.5px solid var(--Neutral-200, #C5D1DB);
  background: #FFF;
}

.supercharge-your-career .career-card .card-head {
  display: flex;
  padding: var(--Spacing-6x, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-4x, 4px);
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) 0px 0px;
  background: var(--Warning-50, #E7EBFD);
}

/* .first-time-user .supercharge-your-career .career-card .card-head {
  background-color: #FFF6E7;
} */
.supercharge-your-career .career-card .card-head .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.supercharge-your-career .career-card .card-head .info-text {
  color: var(--Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.supercharge-your-career .career-card .card-body {
  display: flex;
  padding: 0px var(--Spacing-6x, 20px) var(--Spacing-6x, 20px) var(--Spacing-6x, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Vertical-Spacing-Large, 24px);
  align-self: stretch;
  cursor: pointer;
}

.supercharge-your-career .category-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--CTA-Active, #1575C6);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 4px;
}

.supercharge-your-career .category-info {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--Neutral-700, #374151);
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.supercharge-your-career .category-type span {
  color: var(--light-neutral-900-text, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.supercharge-your-career .category-type p {
  color: #D9D9D9;
}

.supercharge-your-career .category-type .likes {
  color: var(--Success-600, #0E7819);
}

.supercharge-your-career .view-all-btn {
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
}

.active-jobs-list .job-card-view .save-hide-job button {
  display: flex;
  justify-content: flex-end;
  color: var(--Neutral-900, #111827);
  /* font-family: 'Inter', sans-serif; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: capitalize;
  padding: 0;
}

.active-jobs-list .job-card-view .save-hide-job .save-job-btn .MuiButton-icon {
  color: #1575c6;
}

.active-jobs-list .job-card-view .save-hide-job button .MuiButton-startIcon {
  margin-right: 5px;
}

.active-jobs-list .job-card-view .save-hide-job .saved-btn:hover::after {
  opacity: 1;
}

.active-jobs-list .job-card-view .save-hide-job .saved-btn .MuiButton-startIcon {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid var(--Neutral-400, #9ca3af);
  background: var(--Neutral-400, #9ca3af);
  padding: 0.1rem;
}

.active-jobs-list .job-card-view .save-hide-job .saved-btn::after {
  content: 'Unsave Job';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.active-jobs-list .job-card-view .save-hide-job .hide-job-btn {
  color: black;
  background-color: transparent;
  border: 1px solid transparent;
}

.active-jobs-list .job-card-view .save-hide-job .hide-job-btn.disabled {
  color: grey;
}

.choose-template-options .choose-template-btn {
  background: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  min-width: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.choose-template-options .choose-template-btn.activated-template-resume {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
  border: 1px solid var(--CTA-Active, #1575c6);
}

/* .choose-template-options .choose-template-btn:hover {
  -webkit-transform: scale(1.15);  
  -moz-transform: scale(1.15);
  transform: scale(1.15);  
  border: 1px solid var(--CTA-Active, #1575C6);
} */
.choose-template-options .choose-template-btn .material-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 4.8px;
  overflow: hidden;
}

.chat-bot-drawer-container .chat-row.message-out .selected-resume-wrapper {
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Neutral-50, #F9FAFF);
  padding: 8px;
}
.chat-bot-drawer-container .chat-row.message-out .selected-resume-wrapper .resume-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.chat-bot-drawer-container .selected-resume-wrapper .change-resume-btn {
  display: inline-flex;
  position: absolute;
  right: -5px;
  top: 40vh;
  padding: 16px;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 1px solid var(--Secondary-Grey-Light, #f1f5f7);
  border-right: 1px solid var(--Secondary-Grey-Light, #f1f5f7);
  border-bottom: var(--Spacing-1x, 2px) solid var(--Secondary-Grey-Light, #f1f5f7);
  border-left: 1px solid var(--Secondary-Grey-Light, #f1f5f7);
  background: #fff;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.2);
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  text-transform: capitalize;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.chat-bot-drawer-container .selected-resume-wrapper:hover .change-resume-btn {
  opacity: 1;
}

.chat-bot-drawer-container .edit-ans .edit-button {
  visibility: hidden;
}

.chat-bot-drawer-container .edit-ans:hover .edit-button {
  visibility: visible;
}

.chat-bot-drawer-container .chat-bot-submit-app-btn {
  display: inline-block;
  color: var(--White, #fff);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  height: var(--Card-Bg-Height, 48px);
  padding: 8px var(--Spacing-6x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  flex: 1 0 0;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575c6);
  text-transform: capitalize;
  width: 240px;
  margin-left: 2rem;
}

.resume-onboarded-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: auto;
  justify-content: space-between;
}
.resume-onboarded-card .card-top {
  display: flex;
  width: 100%;
  padding: 20px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--Neutral-100, #E7EBFD);
  background: var(--White, #FFF);
  position: relative;
  z-index: 2;
}
.resume-onboarded-card.selected .card-top {
  background-color: #E8F1F9;
  border: 1px solid #5462FF;
}
.resume-onboarded-card .card-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: var(--Primary-Light, #5462FF);
  width: 100%;
  padding: 22px 16px 12px 16px;
  z-index: 1;
  margin-top: -10px;
  cursor: pointer;
}
.resume-onboarded-card .card-bottom p {
  color: var(--White, #FFF);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.resume-onboarded-card .recommended-label {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: -12px;
  border-radius: 4px;
  border: 0.5px solid var(--Success, #29C138);
  background: #E2FFE5;
}
.resume-onboarded-card .recommended-label p {
  color: var(--Success-600, #0E7819);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
}
.resume-onboarded-card .MuiFormControlLabel-root {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: none;
}
.resume-onboarded-card .MuiRadio-root {
  padding: 0;
}
.resume-onboarded-card .MuiFormControlLabel-label {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}
.resume-onboarded-card .list-item p {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.resume-onboarded-card-container .MuiButton-root.Mui-disabled {
  color: #ffffff;
  background-color: #CCE0F4;
}
.resume-onboarded-card .MuiRadio-root .MuiSvgIcon-root path {
  fill: #D2D5DB;
}
.resume-onboarded-card .MuiRadio-root.Mui-checked .MuiSvgIcon-root,
.resume-onboarded-card .MuiRadio-root.Mui-checked .MuiSvgIcon-root path {
  fill: #1C3FCA;
}
.filter-by-overlay .MuiPopover-paper {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Primary-Colours-Neutral-300, #d2d5db);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  overflow: inherit;
}

.filter-by-overlay .filter-by-tabs-item {
  position: relative;
  background: var(--Light-Neutral-100, #f5f6fb);
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.filter-by-overlay .filter-by-tabs-item .MuiButtonBase-root {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  padding: 20px 20px;
  text-transform: capitalize;
  align-items: flex-start;
  border-bottom: 1px solid #ECEDF3;
}

.filter-by-overlay .filter-by-tabs-item .MuiButtonBase-root.Mui-selected {
  background-color: #ffffff;
}

.filter-by-overlay .filter-by-tabs-item .MuiButtonBase-root.Mui-selected:first-child {
  border-top-left-radius: 12px;
  border-bottom: 1px solid #ECEDF3;
}

.filter-by-overlay .filter-by-tabs-item .MuiTabs-indicator {
  display: none;
}

.filter-by-overlay .filter-by-tabs-content {
  position: relative;
  display: block;
}

.filter-by-overlay .filter-by-tabs-content .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
  margin-bottom: 10px;
}

.filter-by-overlay .filter-by-tabs-content .MuiRadioGroup-root .MuiTypography-root {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.filter-by-overlay .filter-by-actions-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--Vertical-Spacing-Large, 20px);
  border-radius: var(--Spacing-6x, 20px) var(--Spacing-6x, 20px) 0px 0px;
  border-top: 1px solid var(--Light-Neutral-300, #d2d5db);
  background: var(--Shade-White, #fff);
  border-bottom-right-radius: 12px;
}

.filter-by-overlay .filter-by-actions-btn button {
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  border-radius: 12px;
}

.header .header-top .candidate-name {
  color: var(--White, #fff);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 150% */
  cursor: pointer;
}

.header .header-top .profile-dp-btn {
  padding-left: 0;
  padding-right: 0;
}

/* .profile-menu .MuiMenu-paper .MuiMenu-list li {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
} */
.external-apply-now-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  /* padding: 8px var(--Spacing-6x, 20px); */
  justify-content: center;
  align-items: center;
  /* gap: var(--Spacing-4x, 10px); */
  border-radius: var(--Spacing-4x, 12px) !important;
  background: var(--CTA-Active, #1575c6) !important;
  color: var(--White, #fff) !important;
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize !important;
}

.action-dialog-card.external-list-dialog .MuiDialog-paper {
  width: 600px;
}

.chip-job-applied {
  border-radius: 8px;
  background: #f1ecff !important;
}

.chip-job-applied .MuiChip-label {
  color: var(--Accent-Dark, #3d1c9b);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.external-job-app {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: var(--Spacing-6x, 20px);
  flex: 1 0 0;
  border-radius: 8px;
  background: #f1ecff;
  margin: 20px 20px 0;
}

.external-job-app .ext-title {
  color: var(--Neutral-900, #111827);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 192.857% */
}

.external-job-app .ext-info-text {
  color: var(--Neutral-600, #4b5563);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.external-job-app .updated-date {
  overflow: hidden;
  color: var(--Neutral-500, #6b7280);
  text-overflow: ellipsis;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.external-job-app .job-applied-btn {
  color: var(--CTA-Active, #1575c6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 8px;
  border: 1px solid var(--CTA-Active, #1575c6);
  background: var(--White, #fff);
  text-transform: capitalize;
}

.my-internal-jobs .chip-job-status {
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
  padding: var(--Spacing-4x, 12px) 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  margin-bottom: 0.2rem;
}

.my-internal-jobs .chip-job-status.job-applied {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.my-internal-jobs .chip-job-status.job-in-progress {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.my-internal-jobs .chip-job-status.job-saved {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.my-internal-jobs .chip-job-status.job-shortlisted {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.my-internal-jobs .chip-job-status.job-hold {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.my-internal-jobs .chip-job-status.job-rejected {
  color: var(--Destructive, #c12929);
  background: var(--Destructive-50, #ffe9e7);
}


.job-applied {
  color: var(--Accent-Dark, #3d1c9b);
  background: #f1ecff;
}

.job-rejected {
  color: var(--Destructive, #c12929);
  background: var(--Destructive-50, #ffe9e7);
}

.my-internal-jobs .updated-date {
  overflow: hidden;
  color: var(--Neutral-500, #6b7280);
  text-overflow: ellipsis;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.12px;
}

.active-jobs-list .search-wrapper .cancel-btn {
  color: var(--CTA-Active, #1575c6);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}

/* .active-jobs-list .filter-wrapper,
.active-jobs-list .search-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 1rem;
} */
.track-job-applications .application-pending {
  display: flex;
  padding: var(--Spacing-6x, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Warning-50, #FFF6E7);
}

.track-job-applications .application-pending .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.track-job-applications .application-pending .info-text {
  color: var(--Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.track-job-applications .application-pending .view-all {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.applied-jobs-toggle-btn {
  padding: 4px 5px;
  border-radius: 8px !important;
  border: 1px solid var(--Neutral-200, #C5D1DB);
  background: var(--Neutral-50, #F9FAFF);
}

.applied-jobs-toggle-btn button {
  border: none;
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  padding: 5px 10px;
  text-transform: capitalize;
}

.applied-jobs-toggle-btn button:hover {
  border-radius: 8px;
}

.applied-jobs-toggle-btn button.Mui-selected,
.applied-jobs-toggle-btn button.Mui-selected:hover {
  border-radius: 8px;
  background: var(--CTA-Active, #1575C6);
  color: #ffffff;
}

.exp-new-opp-slider {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.exp-new-opp-slider .slick-track {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
}

.track-job-applications .card-wrapper .card-content-bottom.application-pending {
  background-color: #C17229;
}

.track-job-applications .card-wrapper .app-pending-chip {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #FFF6E7;
  color: var(--Warning, #C17229);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  height: 20px;
}

.track-job-applications .card-wrapper .app-pending-chip .MuiChip-label {
  padding: 0;
}


/* Admin Screens Style  */

.admin-new-job-posting .page-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 144.444% */
}

.admin-new-job-posting .job-post-toggle-btn .MuiButtonBase-root {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
  /* border: none; */
  border-radius: 8px;
}

.admin-new-job-posting .job-post-toggle-btn .MuiButtonBase-root.Mui-selected {
  background: var(--CTA-Active, #1575C6);
  color: #ffffff;
}

.admin-new-job-posting .job-post-toggle-btn .MuiToggleButtonGroup-lastButton {
  border-left: 1px solid rgba(145, 158, 171, 0.24);
}

.admin-new-job-posting .card-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed #9ca3af;
  border-radius: 20px;
}

.admin-new-job-posting .card-container .card-title {
  display: flex;
  padding: var(--Spacing-6x, 20px) 40px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--Spacing-6x, 20px) var(--Spacing-6x, 20px) 0px 0px;
  background: #DEF1FF;
}

.admin-new-job-posting .card-container .card-title .title-text {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: 0.18px;
}

.admin-new-job-posting .card-container .card-title .info-text {
  color: var(--Light-Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.admin-new-job-posting .card-container .card-body {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}

.job-posting-skills .MuiChip-root {
  border-radius: 8px;
  /* background: var(--CTA-Active, #1575C6); */
  margin-right: 8px;
  margin-bottom: 8px;
}

.job-posting-skills .MuiChip-root.selected {
  background: var(--CTA-Active, #1575C6);
}

.job-posting-skills .MuiChip-root .MuiChip-deleteIcon {
  fill: #ffffff;
}

.job-posting-skills .MuiChip-label {
  /* color: var(--White, #FFF); */
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.job-posting-skills .add-more-skills-link {
  color: var(--Primary-Light, #1575C6);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.job-posting-skills .chip_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 50px;
}

.job-posting-skills .chip_container .MuiIconButton-root {
  padding: 0;
  margin-right: 0.4rem;
}

.job-posting-skills .chip_container .MuiIconButton-root .MuiSvgIcon-root {
  width: 12px;
  height: 12px;
  fill: rgb(250, 175, 0);
}

.job-posting-skills .chip_container .MuiIconButton-root.selected-star .MuiSvgIcon-root {
  fill: #44b700;
}

.admin-new-job-posting .continue-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 6px var(--Spacing-6x, 60px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575C6);
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

.admin-new-job-posting .sad-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 6px var(--Spacing-6x, 60px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--CTA-Active, #1575C6);
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}

.admin-new-job-posting .reset-btn {
  color: var(--Primary-Light, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.admin-new-job-posting .need-help-txt {
  color: var(--Light-Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin-top: 0.5rem;
}

.admin-new-job-posting .need-help-txt a {
  color: var(--CTA-Active, #1575C6);
  text-decoration: none;
}

.admin-new-job-posting .label-text {
  color: var(--Light-Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.admin-new-job-posting .confi-checkbox .MuiTypography-root {
  color: var(--Light-Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.screening-que-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.screening-que-container .screening-que-index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.screening-que-container .screening-que-index .title {
  color: #111827;
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.screening-que-container .screening-que-index .info-text {
  color: var(--light-neutral-900-text, #111827);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.theme-contained-btn {
  border-radius: var(--Spacing-4x, 12px) !important;
  background: var(--CTA-Active, #1575C6) !important;
  color: var(--White, #FFF) !important;
  text-align: center !important;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
}

.screening-que-container .job-type-text {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.screening-que-container .screening-que-index .skip-btn {
  color: var(--Primary-Light, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.screening-que-container .screening-que-list .delete-icon-btn {
  border-radius: 4px;
  background: #FEE;
}

.screening-que-container .screening-que-list .que-text {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.screening-que-container .screening-que-list .next-que-btn {
  color: var(--Primary-Light, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.screening-que-container .screening-que-list .next-que-btn.Mui-disabled {
  color: var(--Neutral-400, #9CA3AF);
}

.candidate-details-content {
  height: 100%;
  display: block;
  overflow: hidden;
  padding: 90px 40px 20px;
}

.candidate-details-content .candidate-det-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  padding-bottom: 40px;
}

.candidate-details-content .candidate-det-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* new job preferences style */

.job-preferences-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.job-preferences-page .job-preferences-content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 2rem;
}

.job-preferences-page .job-preferences-content-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.job-preferences-page .current-job-det .notice-peroid-neg-check .MuiTypography-root {
  color: var(--Light-Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.job-preferences-page .annual-compensation .compensation-det-block {
  display: flex;
  /* align-items: center; */
  padding: var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) var(--Spacing-6x, 20px);
  gap: var(--Spacing-4x, 12px);
  flex: 1 0;
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Neutral-50, #F9FAFF);
  height: 100%;
  width: 100%;
}

.job-preferences-page .annual-compensation .compensation-det-block .before-comp-amt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.job-preferences-page .annual-compensation .compensation-det-block .comp-text {
  color: var(--Light-Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.job-preferences-page .annual-compensation .compensation-det-block .comp-amt {
  color: var(--Neutral-700, #374151);
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
}

.custom-select-icon {
  width: 24px !important;
  height: 24px !important;
}

.login-page-wrapper .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  /* font-family: "Inter", sans-serif; */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.login-page-wrapper .info-text {
  color: var(--Light-Neutral-600, var(--Neutral-600, #4B5563));
  /* font-family: "Inter", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.register-form-wrapper .text-primary {
  width: 80%;
}

input:-webkit-autofill {
  appearance: none;
  background-color: white !important;
  /* Replace with desired background color */
  box-shadow: 0 0 0px 1000px white inset !important;
  /* Override autofill background */
  -webkit-text-fill-color: black !important;
  /* Text color */
  transition: background-color 5000s ease-in-out 0s;
  /* Prevent sudden autofill color flicker */
}

.track-job-applications .text-sub .view-all {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.explore-new-opp .text-1 .view-all {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.job-full-details .job-full-details-header .save-btn svg {
  width: 24px;
  height: 24px;
}

/* External job application dialog style  */

.external-job-app-dialog .MuiPaper-root {
  width: 580px;
}

.external-job-app-dialog .MuiPaper-root {
  border-radius: 16px;
  background: #FFF;
}

.external-job-app-dialog .MuiRadio-root {
  padding-top: 0;
}

.external-job-app-dialog .MuiCard-root .MuiCardContent-root,
.external-job-app-dialog .MuiCard-root .MuiCardActions-root {
  padding: 25px 30px
}

.external-job-app-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.external-job-app-dialog .info-text {
  color: var(--Light-Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.external-job-app-dialog .MuiFormControlLabel-root {
  display: flex;
  padding: var(--Spacing-4x, 12px);
  align-items: flex-start;
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--Neutral-200, #C5D1DB);
  margin-right: 0;
}

.external-job-app-dialog .active-radio {
  border: 1px solid #1575C6;
}

.external-job-app-dialog .active-radio .option-title {
  color: #1575C6;
}

.external-job-app-dialog .option-title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  margin-bottom: 0.3rem;
}

.external-job-app-dialog .option-text {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.external-job-app-dialog .cancel-btn {
  color: var(--CTA-Active, #1575C6);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575C6);
  padding: 12px 0px;
  text-transform: capitalize;
}

.external-job-app-dialog .continue-btn {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  border-radius: var(--Spacing-4x, 12px);
  /* background: var(--Accent-Neutral, #CCE0F4); */
  text-transform: capitalize;
  padding: 12px 20px;
}

.dashboard-content-wrapper .dashboard-candi-title {
  position: relative;
  width: 100%;
  display: block;
  padding: 40px 40px 0;
}

.job-app-drawer-container .annual-compensation .compensation-det-block {
  display: flex;
  align-items: center;
  padding: var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) var(--Spacing-4x, 12px) var(--Spacing-6x, 20px);
  gap: var(--Spacing-4x, 12px);
  flex: 1 0;
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--Neutral-50, #F9FAFF);
  height: 100%;
  width: 100%;
}

.job-app-drawer-container .annual-compensation .compensation-det-block .before-comp-amt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.job-app-drawer-container .annual-compensation .compensation-det-block .comp-text {
  color: var(--Light-Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.job-app-drawer-container .annual-compensation .compensation-det-block .comp-amt {
  color: var(--Neutral-700, #374151);
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.job-app-drawer-container .job-drawer .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input::placeholder {
  color: var(--Dark-Neutral-500, #6b7280);
}

.job-app-drawer-container .job-drawer .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input::placeholder {
  color: var(--Light-Neutral-300, #D2D5DB);
}

.job-app-drawer-container .job-drawer .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background: var(--Neutral-50, #ffffff);
}

.job-app-drawer-container .job-drawer .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
  color: var(--Neutral-400, #b0b3b8);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.4) !important;
}

.quill-container {
  border-radius: 10px;
  transition: border 0.3s ease-in-out;
}

.quill-container .ql-container {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  transition: border 0.3s ease-in-out;
}

.quill-container:hover .ql-container {
  border: 1px solid #212B36;
}

.quill-container .ql-container:focus-within {
  border: 1.3px solid #1976d2;
}

.ql-editor {
  border-radius: 10px;
  font-style: normal;
}

.ql-container {
  font-family: Public Sans, sans-serif !important;
}

ql-editor.ql-blank::before {
  font-style: normal;
}

/* Quill editor */
.ql-editor ul {
  padding-left: 0 !important;
}

.active-jobs-list .filter-wrapper button[aria-owns="filter-by"] {
  background-color: #1575C6;
  border-color: #1F9CD7;
}

.active-jobs-list .filter-wrapper button[aria-owns="filter-by"] svg path {
  fill: #ffffff;
}

.active-jobs-list .filter-wrapper button.search-active {
  background-color: #1575C6;
  border-color: #1F9CD7;
}

.active-jobs-list .filter-wrapper button.search-active svg path {
  fill: #ffffff;
}

.active-jobs-list {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-left: 1rem; */
}

.active-jobs-list .header-wrapper .MuiIconButton-root .ellipse-icon {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
}

/* Empty state style */
.emtpy-state-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  padding: 40px 40px 0;
}

.emtpy-state-wrapper .empty-state-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-right: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-bottom: 1px solid var(--Neutral-200, #C5D1DB);
  border-left: 0.5px solid var(--Neutral-200, #C5D1DB);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px #0000000D;
  padding: 20px;
}

.empty-state-card .title {
  font-family: "Inter", sans-serif;
  color: var(--Neutral-900, #111827);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.empty-state-card .info-text {
  /* font-family: "Inter", sans-serif; */
  color: var(--Neutral-900, #111827);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin-bottom: 1rem;
}

.empty-state-card .browse-jobs-btn {
  border-radius: var(--Spacing-4x, 12px) !important;
  background: var(--Primary-Light, #1575C6) !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 8px 30px !important;
  text-transform: capitalize !important;
}

.empty-state-ads-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-right: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-bottom: 1px solid var(--Neutral-200, #C5D1DB);
  border-left: 0.5px solid var(--Neutral-200, #C5D1DB);
  background: #FFF;
  padding: 40px 30px;
}

.empty-state-ads-container .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  /* font-family: "Inter", sans-serif; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
}

.emtpy-state-wrapper .scroll-content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.emtpy-state-wrapper .scroll-content-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.basic-profile-main-heading {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: 'Inter', sans-serif !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  /* 133.333% */
}

.hide-my-info {
  content: '';
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1;
}

.update-skills-drawer-container .select-relevent-skills-v2 {
  background: var(--Neutral-50, #E7EBFD);
  padding: 12px 10px 5px 10px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.update-skills-drawer-container .select-relevent-skills-v2 .MuiChip-root {
  border: 0.5px solid var(--Neutral-500, #6B7280);
  border-radius: 8px;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  height: auto;
  background: var(--Neutral-50, #F9FAFF);
}

.update-skills-drawer-container .select-relevent-skills-v2 .MuiChip-root .MuiChip-label {
  font-family: "Inter", sans-serif;
  color: var(--Neutral-500, #6B7280);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 0;
}

.update-skills-drawer-container .select-relevent-skills-v2 .MuiChip-root.selected-skill {
  border: 0.5px solid var(--Neutral-500, #1575C6);
  background: var(--Neutral-50, #1575C6);
}

.update-skills-drawer-container .select-relevent-skills-v2 .MuiChip-root.selected-skill .MuiChip-label {
  background: var(--Neutral-50, #1575C6);
  color: var(--Neutral-50, #F9FAFF);
  font-weight: 600;
}

/* description quill in job posting */
.quill-container-job {
  border-radius: 10px;
  transition: border 0.3s ease-in-out;
  border-color: red;
}

.quill-container-job .ql-container {
  border-radius: 0 0 10px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  transition: border 0.3s ease-in-out;
}

.quill-container-job:hover .ql-container {
  border: 1px solid #212B36;
}

.quill-container-job .ql-container:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-editor {
  border-radius: 0 0 10px 10px !important;
  font-style: normal;
}

.quill-container-job .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
}

.quill-container-job:hover .ql-toolbar.ql-snow {
  border: 1px solid #212B36;
}

.quill-container-job .ql-toolbar.ql-snow:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-container:focus-within,
.quill-container-job .ql-toolbar:focus-within {
  border: 1.3px solid #1976d2;
}

.error-quill .ql-container {
  border: 1.3px solid red !important;
}

.error-quill .ql-toolbar {
  border: 1.3px solid red !important;
  border-bottom: transparent !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-family: Public Sans, sans-serif !important;
  font-weight: 400;
}

.quill:focus-within .ql-toolbar.ql-snow {
  border-color: #1976d2;
}

.quill-container-job .ql-container:focus .ql-toolbar.ql-snow {
  border-color: #1976d2 !important;
}

/* quill listing css */
.list-style {
  overflow: visible !important;
  /* Make sure to include !important here */
}

.list-style ul {
  list-style-type: disc;
  margin-left: 20px;
  padding: 2px;
}

.list-style ol {
  list-style-type: decimal;
  margin-left: 20px;
  padding: 2px;
}

.list-style li {
  margin-bottom: 1px;
  padding: 2px;
}

/* star icon */
/* .job-posting-skills .chip_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 50px;
} */
.job-posting-skills .chip_container .MuiIconButton-root {
  padding: 0;
  /* margin-right: 0.4rem; */
}

.job-posting-skills .chip_container .MuiIconButton-root .MuiSvgIcon-root {
  /* width: 12px;
  height: 12px; */
  /* fill: rgb(250, 175, 0); */
  font-size: 15px;
}

/* .job-posting-skills .chip_container .MuiIconButton-root.selected-star .MuiSvgIcon-root { */
/* fill: #44b700; */
/* } */
.job-full-details .skills-match-card .MuiChip-root .MuiChip-label {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-right: 0;
  padding-left: 0;
}

.job-full-details .skills-match-card .MuiChip-root .MuiChip-label .star-icon {
  padding-right: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(255, 255, 255, 0.8); */
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}


.candidate-match-analysis .MuiAccordion-root {
  padding: var(--Spacing-6x, 14px) 16px;
  border-radius: var(--Spacing-4x, 12px) !important;
  border: 1px solid var(--Neutral-200, #C5D1DB);
  background: var(--Neutral-50, #F9FAFF);
  box-shadow: none;
}

.candidate-match-analysis .MuiAccordion-root::before {
  display: none;
}

.candidate-match-analysis .MuiAccordionSummary-root {
  padding: 0;
}

.candidate-match-analysis .MuiAccordionSummary-content {
  margin: 0;
}

.candidate-match-analysis .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.candidate-match-analysis .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.candidate-match-analysis .MuiAccordionDetails-root {
  padding: 15px 0 0;
}

.candidate-match-analysis .summary-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.candidate-match-analysis .summary-info {
  color: #808080;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

/* React Quill Style */

.quill-container-job {
  border-radius: 10px;
  transition: border 0.3s ease-in-out;
  border-color: red;
}

.quill-container-job .ql-container {
  border-radius: 0 0 10px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  transition: border 0.3s ease-in-out;
}

.quill-container-job:hover .ql-container {
  border: 1px solid #212B36;
}

.quill-container-job .ql-container:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-editor {
  border-radius: 0 0 10px 10px !important;
  font-style: normal;
}

.quill-container-job .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
}

.quill-container-job:hover .ql-toolbar.ql-snow {
  border: 1px solid #212B36;
}

.quill-container-job .ql-toolbar.ql-snow:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-container:focus-within,
.quill-container-job .ql-toolbar:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-container:focus .ql-toolbar.ql-snow {
  border-color: #1976d2 !important;
}

.error-quill .ql-container,
.error-quill .ql-toolbar {
  border: 1.3px solid red;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.quill:focus-within .ql-toolbar.ql-snow {
  border-color: #1976d2;
}

/* Page header style */

.page-header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}

.page-header .MuiButton-root,
.page-footer .MuiButton-root {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  padding: 8px 24px;
  border-radius: 8px;
  background: var(--Primary-Light, #1575C6);
}

.page-header .for-emp {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.menu-links a {
  color: var(--Neutral-50, #F9FAFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
}

.page-footer {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
}

.page-footer .text-1 {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 100% */
  text-align: center;
}

.page-footer .text-2 {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
  opacity: 0.6;
  text-align: center;
  margin-bottom: 1rem;
}

.page-footer .follow-us-txt {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
  opacity: 0.5;
}

.page-footer .MuiIconButton-sizeMedium {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
}

.page-footer .linkedIn-btn,
.page-footer .linkedIn-btn:hover {
  background: #1275B1;
}

.page-footer .twitter-btn,
.page-footer .twitter-btn:hover {
  background: #ffffff;
}

/* Terms & conditions / privacy policy style */

.terms-conditions {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  padding: 100px 20px 60px;
}

.terms-conditions .page-title {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.terms-conditions h6 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.terms-conditions p {
  color: var(--Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 131.25% */
}

/* 
.pdf-viewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 3s ease-in-out;
}

.fade-out {
  opacity: 0;
} */

.editor-view {
  top: 0px;
  bottom: 0px;
  right: 0px;
  /* width: 50%; */
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-flow: column;
  /* background-color: rgb(101, 110, 131); */
  color: rgb(255, 255, 255);
  user-select: none;
}

.editor-view .pdf-view-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  inset: 60px 40px 50px;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 20px;
  width: 100%;
}

.editor-view .pdf-container {
  position: relative;
  overflow: hidden;
  width: auto;
  height: calc(78vh - 40px);
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}

.editor-view .pdf-fade-in {
  transform: scale(1);
  transform-origin: left top;
  opacity: 1 !important;
  transition: none;
  z-index: 1;
}

.editor-view .pdf-fade-out {
  transform: scale(1);
  transform-origin: left top;
  opacity: 0 !important;
  z-index: 2;
  transition: opacity 0.2s;
}

.editor-view .pdf-nav-block {
  height: 40px;
  display: flex;
  align-items: center;
  place-content: center;
  flex-flow: column;
  row-gap: 20px;
}

.editor-view .pdf-nav-block .rendering-block {
  position: absolute;
  left: 0;
}

.editor-view .pdf-nav-block .rendering-status {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.editor-view .pdf-nav-block .status-icon {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-view .pdf-nav-block .page-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-view .pdf-nav-block .page-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-view .page-nav .prev-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 14px;
  background-color: rgba(15, 20, 30, 0);
  transition: background-color 0.1s;
  opacity: 1;
  pointer-events: auto;
  transform: rotate(180deg);
}

.editor-view .page-nav .prev-icon.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.editor-view .page-nav .next-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 14px;
  background-color: rgba(15, 20, 30, 0);
  transition: background-color 0.1s;
  opacity: 1;
  pointer-events: auto;
  color: rgb(255, 255, 255);
}

.editor-view .page-nav .next-icon.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.editor-view .page-nav .page-count {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 6px;
  font-variant-numeric: tabular-nums;
}

/* .terms-conditions .content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
  padding: 100px 20px 40px;
}
.terms-conditions .content-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

/* Responsive media query for mobile */

.update-skills-drawer-container .select-relevent-skills .MuiChip-root {
  border: 0.5px solid var(--Neutral-500, #6B7280);
  border-radius: 8px;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  height: 28px;
}

.update-skills-drawer-container .select-relevent-skills .MuiChip-root .MuiChip-label {
  font-family: "Inter", sans-serif;
  color: var(--Neutral-500, #6B7280);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 0;
}

.update-skills-drawer-container .select-relevent-skills .MuiChip-root.selected-skill {
  border: 0.5px solid var(--Neutral-500, #1575C6);
}

.update-skills-drawer-container .select-relevent-skills .MuiChip-root.selected-skill .MuiChip-label {
  color: var(--CTA-Active, #1575C6);
  font-weight: 600;
}

.update-skills-drawer-container .applicable-skills {
  display: flex;
  padding: var(--Spacing-4x, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-100, #E7EBFD);
}

.update-skills-drawer-container .applicable-skills .selected-app-skill {
  border-radius: 8px;
  border: 0.5px solid var(--Neutral-50, #F9FAFF);
  background: var(--CTA-Active, #1575C6);
}

.update-skills-drawer-container .applicable-skills .selected-app-skill .MuiChip-label {
  color: #ffffff;
}

.update-skills-drawer-container .applicable-skills .text-info {
  color: var(--Neutral-800, #1F2937);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

/* Admin dashboard page style */

.admin-activ-jobs-full-details {
  position: relative;
  width: 100%;
  background: #FFF;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.admin-activ-jobs-full-details .header-top {
  position: relative;
  width: 100%;
  padding: 20px;
  background: #FFF;
}

.admin-activ-jobs-full-details .header-top .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.admin-activ-jobs-full-details .header-top .company-name {
  color: var(--light-neutral-900-text, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.admin-activ-jobs-full-details .header-top svg {
  width: 12px;
  height: 12px;
}

.admin-activ-jobs-full-details .header-top .ratings .rating-count {
  color: var(--Success, #29C138);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.admin-activ-jobs-full-details .header-top .city-name {
  color: var(--light-neutral-900-text, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.admin-activ-jobs-full-details .header-top .download-link {
  color: var(--Primary-Light, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.admin-active-jobs-tabs {
  border-bottom: 1px solid var(--Neutral-200, #C5D1DB);
}

.admin-active-jobs-tabs .MuiTabs-root {
  min-height: 35px;
}

.admin-active-jobs-tabs .MuiButtonBase-root {
  color: var(--Neutral-700, #374151);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
  padding: 6px 16px;
  min-height: 35px;
}

.admin-active-jobs-tabs .MuiButtonBase-root.Mui-selected {
  color: #111827;
  font-weight: 600;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: auto;
  justify-content: space-between;
}

.card-wrapper .card-content {
  display: flex;
  width: 100%;
  padding: 20px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: var(--Spacing-4x, 12px);
  border-top: 1px solid var(--Neutral-100, #E7EBFD);
  border-right: 1px solid var(--Neutral-100, #E7EBFD);
  border-left: 1px solid var(--Neutral-100, #E7EBFD);
  background: var(--White, #FFF);
  position: relative;
  z-index: 2;
}

.card-wrapper .card-content .applied-days {
  color: var(--Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.card-wrapper .card-content-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: var(--Primary-Light, #1575C6);
  width: 100%;
  padding: 22px 20px 12px 20px;
  /* position: absolute; */
  z-index: 1;
  margin-top: -10px;
  background-color: #F0F7FF;
}

.active-jobs-list .post-new-job-btn {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: capitalize !important;
  width: 150px;
  height: 30px;
  display: inline-flex;
  border-radius: 4px;
  background: var(--CTA-Active, #1575C6);
}

.badge {
  position: absolute;
  left: 16px;
  top: -10px;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
}

.badge p {
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.badge.best-fit {
  color: var(--White, #FFF);
  border: 1.25px solid var(--White, #FFF);
  background: var(--Success-600, #0E7819);
}

.badge.highly-suitable {
  color: var(--Success-600, #0E7819);
  border: 1.25px solid var(--White, #FFF);
  background: var(--Success-50, #E7FFEA);
}

.badge.not-suitable {
  color: var(--Destructive, #C12929);
  border: 1.25px solid var(--White, #FFF);
  background: var(--Destructive-50, #FFE9E7);
}

.badge.good-fit {
  color: var(--Success-600, #0E7819);
  border: 1.25px solid var(--White, #FFF);
  background: var(--Success-50, #E7FFEA);
}

.select-status-dp-menu .MuiFormLabel-root {
  color: var(--CTA-Active, #1575C6);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.select-status-dp-menu .MuiSelect-select {
  height: 30px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 28px;
}

.select-status-dp-menu .MuiSvgIcon-root {
  color: var(--CTA-Active, #1575C6);
}

.card-wrapper .card-content .candidate-name {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.career-highlights-card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px !important;
  border: 1.064px solid var(--Accent-Neutral, #CCE0F4);
  background: var(--Neutral-50, #F9FAFF) !important;
  box-shadow: none !important;
}

.career-highlights-card .title {
  color: var(--Neutral-600, #4B5563);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 0.5rem;
}

.career-highlights-card .info-text {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.candi-profile-det .det-icon {
  display: flex;
  width: var(--Spacing-6x, 20px);
  height: var(--Spacing-6x, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: var(--Spacing-6x, 20px);
  background: var(--Accent-Neutral, #CCE0F4);
}

.candi-profile-det .det-icon svg {
  width: 12px;
}

.candi-profile-det p {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.card-wrapper .card-content-bottom .text-title {
  color: var(--Neutral-600, #4B5563);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.card-wrapper .card-content-bottom .text-info {
  color: var(--Neutral-600, #4B5563);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.card-wrapper .good-fit-card-bottom {
  background-color: #204b79 !important;
}

.card-wrapper .good-fit-card-bottom p {
  color: #ffffff !important;
  font-weight: 400 !important
}

.card-wrapper .good-fit-card-bottom p span {
  color: #ffffff !important
}

.shortlisted-jobs-tabs-content .shortlisted-info-card .card-content {
  border-radius: var(--Spacing-4x, 12px);
  border: 0.5px solid var(--Accent-Neutral, #CCE0F4);
  background: var(--Neutral-50, #F9FAFF);
  border-bottom: 1px solid var(--Neutral-100, #E7EBFD);
}

.shortlisted-info-card .candiate-personal-info .MuiPaper-root {
  border-radius: 16px;
  border-top: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-right: 0.5px solid var(--Neutral-200, #C5D1DB);
  border-bottom: 1px solid var(--Neutral-200, #C5D1DB);
  border-left: 0.5px solid var(--Neutral-200, #C5D1DB);
  background: var(--White, #FFF);
}

.shortlisted-info-card .candiate-personal-info .MuiPaper-root .title {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.shortlisted-info-card .candiate-personal-info .MuiPaper-root .info-text {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.shortlisted-info-card .previous-work-info .title-info {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.shortlisted-info-card .previous-work-info .info {
  overflow: hidden;
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.job-suitablity-match-skill-score .skill-text {
  color: var(--Neutral-700, #374151);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.shortlisted-info-card .job-desc-list li p {
  color: var(--Neutral-700, #374151);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.shortlisted-info-card .skill-score {
  display: flex;
  width: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-1x, 2px);
  align-self: stretch;
  border-radius: var(--Spacing-4x, 12px);
  text-align: center;
  padding: 1rem;
}

.shortlisted-info-card .skill-score p {
  color: var(--Neutral-700, #374151);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 150% */
  letter-spacing: 0.24px;
}

.shortlisted-info-card .skill-score p span {
  text-align: right;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.shortlisted-info-card .skill-score.match p span {
  color: var(--Success-600, #0E7819);
}

.shortlisted-info-card .skill-score.not-match p span {
  color: var(--Destructive, #C12929);
}

.shortlisted-info-card .skill-score.match {
  background: var(--Success-50, #E7FFEA);
}

.shortlisted-info-card .skill-score.not-match {
  background: var(--Destructive-50, #FFE9E7);
}

.company-review-tabs-content .card-content {
  border-radius: var(--Spacing-4x, 12px);
  border: 0.5px solid var(--Accent-Neutral, #CCE0F4);
  border-bottom: 1px solid var(--Neutral-100, #E7EBFD);
}

.reject-candi-dialog .card-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.reject-candi-dialog .card-info-text {
  color: var(--Light-Neutral-600, var(--Neutral-600, #4B5563));
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.reject-candi-dialog .MuiButton-text {
  color: var(--Primary-Light, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.hide-my-info {
  content: '';
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1;
}

.candidate-match-analysis .MuiAccordion-root {
  padding: var(--Spacing-6x, 14px) 16px;
  border-radius: var(--Spacing-4x, 12px) !important;
  border: 1px solid var(--Neutral-200, #C5D1DB);
  background: var(--Neutral-50, #F9FAFF);
  box-shadow: none;
}

.candidate-match-analysis .MuiAccordion-root::before {
  display: none;
}

.candidate-match-analysis .MuiAccordionSummary-root {
  padding: 0;
}

.candidate-match-analysis .MuiAccordionSummary-content {
  margin: 0;
}

.candidate-match-analysis .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.candidate-match-analysis .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.candidate-match-analysis .MuiAccordionDetails-root {
  padding: 15px 0 0;
}

.candidate-match-analysis .summary-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.candidate-match-analysis .summary-info {
  color: #808080;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

/* React Quill Style */

.quill-container-job {
  border-radius: 10px;
  transition: border 0.3s ease-in-out;
  border-color: red;
}

.quill-container-job .ql-container {
  border-radius: 0 0 10px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  transition: border 0.3s ease-in-out;
}

.quill-container-job:hover .ql-container {
  border: 1px solid #212B36;
}

.quill-container-job .ql-container:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-editor {
  border-radius: 0 0 10px 10px !important;
  font-style: normal;
}

.quill-container-job .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
}

.quill-container-job:hover .ql-toolbar.ql-snow {
  border: 1px solid #212B36;
}

.quill-container-job .ql-toolbar.ql-snow:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-container:focus-within,
.quill-container-job .ql-toolbar:focus-within {
  border: 1.3px solid #1976d2;
}

.quill-container-job .ql-container:focus .ql-toolbar.ql-snow {
  border-color: #1976d2 !important;
}

.error-quill .ql-container,
.error-quill .ql-toolbar {
  border: 1.3px solid red;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.quill:focus-within .ql-toolbar.ql-snow {
  border-color: #1976d2;
}

/* Page header style */

.page-header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}

.page-header .MuiButton-root,
.page-footer .MuiButton-root {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  padding: 8px 24px;
  border-radius: 8px;
  background: var(--Primary-Light, #1575C6);
}

.page-header .for-emp {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.menu-links a {
  color: var(--Neutral-50, #F9FAFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
}

.page-footer {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
}

.page-footer .text-1 {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 100% */
  text-align: center;
}

.page-footer .text-2 {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
  opacity: 0.6;
  text-align: center;
  margin-bottom: 1rem;
}

.page-footer .follow-us-txt {
  color: var(--White, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
  opacity: 0.5;
}

.page-footer .MuiIconButton-sizeMedium {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
}

.page-footer .linkedIn-btn,
.page-footer .linkedIn-btn:hover {
  background: #1275B1;
}

.page-footer .twitter-btn,
.page-footer .twitter-btn:hover {
  background: #ffffff;
}

/* Terms & conditions / privacy policy style */

.terms-conditions {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  padding: 100px 20px 60px;
}

.terms-conditions .page-title {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.terms-conditions h6 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.terms-conditions p {
  color: var(--Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 131.25% */
}

/* .terms-conditions .content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
  padding: 100px 20px 40px;
}
.terms-conditions .content-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

/* Responsive media query for mobile */

.pre-resume-btn-block {
  position: fixed;
  bottom: 15vh;
  right: 20px;
}

.pre-resume-btn-block button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 8px !important;
  border-radius: 100px;
  background: var(--Primary-Light, #1575C6);
  color: #FFF;
  /* font-family: "Inter", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  height: 32px;
  width: 32px;
}

.pre-resume-btn-block button:hover {
  background: var(--Primary-Light, #1575C6);
}

.pre-resume-full-btn-block {
  position: fixed;
  bottom: 15vh;
  right: 20px;
}

.pre-resume-full-btn-block button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 8px 16px !important;
  border-radius: 100px;
  background: var(--Primary-Light, #1575C6);
  color: #FFF;
  /* font-family: "Inter", sans-serif; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 30px; */
  text-transform: capitalize;
  /* height: 32px;
  width: 32px; */
}

.preview-resume-dialog .MuiPaper-root .MuiAppBar-root {
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
}

.preview-resume-dialog .MuiPaper-root .preview-resume-title {
  color: var(--White, #FFF);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 131.25% */
}

.job-suit-analysis {
  border: none !important;
  background: var(--Warning-50, #FFF6E7) !important;
}

.job-suit-analysis .MuiCardContent-root {
  padding-top: 2.5rem !important;
}

.job-suit-analysis .MuiButton-root {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--Primary-Light, #1575C6);
}

.job-suit-analysis .lock-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 40px;
  background: var(--Warning, #C17229);
  position: absolute;
  top: -25px;
}


/* /profile-menu */
.profile-menu .MuiPaper-root {
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.profile-menu .MuiMenu-paper .MuiMenu-list li {
  color: var(--Neutral-700, #ffffff);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0.5rem;
}

.profile-menu .menu-text {
  position: relative;
  padding: 0rem 2rem 0.5rem 2rem;
  text-align: center;
}

.profile-menu .menu-text .text {
  color: var(--Neutral-50, rgba(255, 255, 255, 0.70));
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.profile-menu .menu-text .link {
  color: var(--Light-Neutral-50, var(--Neutral-50, #F9FAFF));
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration-color: #ffffff;
}

.profile-menu .MuiDivider-root {
  border-color: #B7C5FF;
  opacity: 0.1;
  margin-top: 1rem !important;
}

.profile-menu .MuiList-root {
  padding: 15px;
}

/* select for external job */
.job-status-select .MuiOutlinedInput-notchedOutline {
  border-color: #1575C6;
}

.job-status-select .MuiInputBase-root svg {
  width: 1em !important;
  height: 1em !important;
}

.job-status-select .MuiInputBase-root svg path {
  fill: #1575C6;
}

.job-status-select .MuiFormLabel-root,
.job-status-select .MuiSelect-select {
  color: #1575C6;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-card-row .slick-slider .slick-list {
  margin: 0 7px 0 25px;
}

.dashboard-card-row .slick-slider button.slick-arrow {
  display: flex !important;
  width: var(--Card-Bg-Height, 48px);
  height: var(--Card-Bg-Height, 48px);
  padding: var(--Spacing-4x, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-6x, 20px);
  border-radius: 16px;
  border: 1px solid var(--Light-Neutral-200, #ECEDF3);
  background: var(--Shade-White, #FFF) !important;
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.dashboard-card-row .slick-slider button.slick-next::before,
.dashboard-card-row .slick-slider button.slick-prev::before {
  display: none !important;
}

.dashboard-card-row .slick-slider button.slick-next {
  background: #ffffff url('../public/assets/arrow-next-png.png') center center no-repeat !important;
  background-size: 30px !important;
  right: -20px;
}

.dashboard-card-row .slick-slider button.slick-prev {
  background: #ffffff url('../public/assets/arrow-prev-png.png') center center no-repeat !important;
  background-size: 30px !important;
  left: -25px;
  z-index: 1;
}

/* skills matching */
.job-full-details .MuiChip-root.match-skill {
  border: 0.5px solid var(--Success-600, #0E7819);
  background: rgba(197, 255, 203, 0.50);
}

.job-full-details .MuiChip-root.unmatch-skill {
  border: 0.5px solid var(--Warning, #C17229);
  background: rgba(255, 233, 231, 0.50);
}

.job-full-details .MuiChip-root.destructive-skill {
  border: 0.5px solid var(--Destructive, #C12929);
  background: rgba(255, 233, 231, 0.50);
}

.job-full-details .MuiChip-root.destructive-skill .MuiChip-icon.error-match-icon {
  background-color: #C12929;
}

.job-full-details .MuiChip-root.destructive-skill .MuiChip-icon.error-match-icon svg path {
  fill: #ffffff;
}

/* Track & manage application status */
.track-manage-application {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 320px;
  top: 0;
  right: 0;
  height: 310px;
  background: var(--Warning-50, #FFF6E7);
  overflow: hidden;
}

.track-manage-application .track-manage-app-container {
  overflow-y: auto;
  padding: 32px;
  height: 100%;
}

.track-manage-application .track-manage-app-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.track-manage-application .title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.track-manage-application .info-text {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.track-manage-application .tm-card-wrapper {
  position: relative;
  display: block;
  border-radius: 12px;
  border: 1px solid var(--Neutral-100, #E7EBFD);
  background: var(--White, #FFF);
  padding: 12px;
}

.track-manage-application .tm-card-wrapper .post-name {
  display: -webkit-box;
  max-width: 276px;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: #111827;
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.track-manage-application .tm-card-wrapper .company-name {
  display: -webkit-box;
  max-width: 276px;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: #111827;
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.track-manage-application .tm-card-wrapper .MuiButtonBase-root {
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  border-radius: 8px;
  background: var(--CTA-Active, #1575C6);
  text-transform: capitalize;
  width: 100%;
}

/* Dashobard actions pending style */
.actions-pending-wrapper {
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 320px;
  top: 0;
  right: 0;
  height: 407px;
  background: var(--Warning-50, #FFF6E7);
  overflow: hidden;
}

.actions-pending-wrapper .actions-pending-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.actions-pending-wrapper .actions-pending-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.actions-pending-wrapper .actions-header {
  display: flex;
  padding: 28px 20px;
  align-items: center;
  background: var(--Destructive-50, #FFE9E7);
}

.actions-pending-wrapper .actions-header .MuiList-root .MuiButtonBase-root:hover {
  background-color: transparent;
}

.actions-pending-wrapper .actions-header .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root {
  overflow: hidden;
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.actions-pending-wrapper .actions-header .MuiListItemButton-root .view-text {
  overflow: hidden;
  color: var(--Primary-Light, #1575C6);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.actions-pending-wrapper .actions-header .MuiListItemButton-root .MuiSvgIcon-root[data-testid="ExpandLessIcon"] path,
.actions-pending-wrapper .actions-header .MuiListItemButton-root .MuiSvgIcon-root[data-testid="ExpandMoreIcon"] path {
  fill: #1575C6;
}

.actions-pending-wrapper .actions-header .MuiCollapse-root .actionCard {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Destructive-50, #FFE9E7);
  background: #FFF;
  box-shadow: none;
}

.actions-pending-wrapper .actions-header .MuiCollapse-root .actionCard .title {
  color: var(--Neutral-900, #111827);
  /* font-family: Inter; */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.actions-pending-wrapper .actions-header .MuiCollapse-root .actionCard .msg-text {
  color: var(--Neutral-900, #111827);
  /* font-family: Inter; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.actions-pending-wrapper .actions-header .MuiList-root .MuiCollapse-root {
  margin-top: 28px;
}

.actions-pending-wrapper .actions-header .MuiList-root .MuiCollapse-root .MuiList-root {
  margin-bottom: 12px;
}

.actions-pending-wrapper .actions-header .MuiList-root .MuiCollapse-root .MuiList-root:last-child {
  margin-bottom: 0;
}

.actions-pending-wrapper .actions-thought-container {
  position: relative;
  padding: 28px 20px 20px;
}

.actions-pending-wrapper .actions-thought-container .thought-header .title {
  overflow: hidden;
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  text-overflow: ellipsis;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  opacity: 0.6;
}

.actions-pending-wrapper .actions-thought-container .thought-text {
  color: #111827;
  font-feature-settings: 'ss01' on;
  /* font-family: "Inter", sans-serif; */
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resend-otp-link a {
  color: var(--CTA-Active, #1575C6);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */
}

.step-heading .sub-text {
  color: var(--Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.upload-profile-btn .material-icons {
  display: inline-block;
  align-items: center;
  justify-content: end;
  width: 50px;
  height: 50px;
  border-radius: 4.8px;
  overflow: hidden;
  position: relative;
}

.upload-profile-btn .material-icons img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-wrapper .sidebar-content {
  flex: 1;
  overflow: auto;
}

.sidebar-wrapper .sidebar-header {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 20px;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
}

.sidebar-wrapper .sidebar-header .title {
  color: var(--White, #FFF);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  /* 131.25% */
}

.sidebar-wrapper .sidebar-header .sub-text {
  color: var(--Neutral-200, #C5D1DB);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.sidebar-wrapper .sidebar-header .delete-sec-btn {
  position: absolute;
  right: 20px;
  border-radius: 4px;
  background: #FEE;
}

.sidebar-wrapper .sidebar-content {
  position: relative;
  background-color: #F9FAFF;
  padding: 20px;
}

.mobile-menu {
  display: none !important;
}

.mobile-sidebar {
  display: flex;
  width: 360px;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
}

.mobile-sidebar p {
  color: #FFF;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  /* 155.556% */
}

.mobile-sidebar hr {
  opacity: 0.2;
  border-color: #FFF;
}

.mobile-sidebar .menu-header {
  width: 100%;
  margin-bottom: 2rem;
}

.filter-wrapper .head-title {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.create-update-resume-container {
  position: relative;
  height: 100vh;
  padding: 5rem 1.5rem;
}

@media(min-width: 768px) and (max-width: 991px) {
  .forgot-password-dialog .MuiPaper-root {
    width: 100%;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .otp-dialog-wrapper .MuiPaper-root {
    width: 100%;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .job-full-details .job-full-overview {
    height: 100% !important;
  }

  .active-jobs-container .job-card-view .active-jobs-info .ctc-range .bim-tag {
    position: relative;
    top: 0;
    font-size: 10px;
    padding: 0 4px;
    color: #0E7819;
    background-color: #E7FFEA;
  }

  .add-skills-dialog.MuiPaper-root {
    border-radius: 20px !important;
  }

  .dashboard-content-wrapper .dashboard-candi-title {
    padding: 40px 25px 0;
  }

  .explore-new-opp .explore-new-opp-container {
    padding: 40px 25px;
  }

  .dashboard-page-wrapper .card-wrapper .card-content-top,
  .dashboard-page-wrapper .card-wrapper .card-content-bottom {
    width: 300px;
  }

  .dashboard-page-wrapper .dashboard-content-wrapper {
    height: 100%;
  }
  .upload-photo-stack-wrapper {
    align-items: flex-start!important;
  }
  .perview-resume {
    display: inline-flex;
    width: 80px;
    height: 80px;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    border-radius: 12px;
    border: 1px dashed #000;
  }
  .total-work-exp .count-years {
    width: 100%;
  }
}

@media(max-width: 767px) {
  .xs-fs-12 {
    font-size: 12px !important;
  }

  .xs-fs-14 {
    font-size: 14px !important;
  }

  .xs-fs-16 {
    font-size: 16px !important;
  }

  .xs-fs-24 {
    font-size: 24px !important;
  }

  .mobile-menu {
    display: block !important;
  }

  .header .MuiContainer-root {
    padding: 0;
  }

  .header .logout-btn {
    font-size: 14px;
  }

  .header .logout-btn .MuiSvgIcon-root {
    font-size: 16px;
  }

  .login-page-wrapper {
    /* width: 100%; */
    height: 100%;
  }

  .login-content-wrapper .tj-logo {
    width: 102.82px;
  }

  .login-content-wrapper {
    padding: 40px 20px;
  }

  .register-form-wrapper .text-primary {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
  }

  .forgot-password-dialog .MuiPaper-root,
  .mobile-bottomsheet-dialog .MuiPaper-root {
    width: 100%;
    margin: 0;
    border: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .forgot-password-dialog .MuiCard-root .MuiCardContent-root,
  .mobile-bottomsheet-dialog .MuiCard-root .MuiCardContent-root {
    padding: 24px 16px;
  }

  .mobile-bottomsheet-dialog .ext-job-dialog-wraper {
    padding: 24px 16px;
  }

  .forgot-password-dialog .title {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .forgot-password-dialog .info-text {
    font-size: 12px;
    line-height: 18px;
  }

  .forgot-password-dialog .cancel-btn,
  .otp-card-wrapper .otp-btn {
    border: none;
  }

  .otp-card-wrapper .otp-sent-txt,
  .resend-otp-link a {
    font-size: 12px;
  }

  .otp-card-wrapper .resend-otp-txt,
  .otp-card-wrapper .attempts-txt {
    font-size: 12px;
  }

  .otp-card-wrapper .resend-otp-txt span {
    font-size: 12px;
    font-weight: 400;
  }

  .step-heading {
    font-size: 16px;
  }

  .candidate-details-content {
    /* padding: 70px 20px 20px; */
    padding: 90px 20px 20px;
  }

  .upload-profile-btn .material-icons {
    width: 80px;
    height: 80px;
  }

  .candidate-details-content .candidate-det-wrapper {
    /* height: 100%; */
    height: calc(100vh - 24vh);
    padding-bottom: 50px;
  }

  .add-skills-dialog-content .MuiFormControlLabel-root {
    border: none !important;
  }

  .add-skills-dialog .skills-dialog-actions {
    padding: 24px 16px;
  }

  .add-skills-dialog .add-skills-dialog-content {
    height: auto;
  }

  .page-content {
    padding: 70px 20px 20px !important;
  }

  .job-preferences-page .MuiContainer-root {
    padding: 0;
  }

  .job-preferences-page .job-preferences-content-wrapper {
    /* height: 100%;
    padding-bottom: 0; */
    height: calc(100vh - 18vh);
    padding-bottom: 4rem;
  }

  .career-breaks-dialog .career-breaks-footer {
    padding: 16px;
  }

  .career-breaks-dialog .cbi-update-btn {
    width: 100%;
  }

  .career-breaks-dialog .MuiFormControlLabel-root {
    align-items: center;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 0;
    padding-left: 0;
  }

  .candidate-basic-det-wrapper .info-list-item .title-wrapper {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .candidate-basic-det-wrapper .info-list-item .MuiCardContent-root {
    padding: 12px !important;
  }

  .dashboard-content-wrapper .dashboard-candi-title {
    padding: 20px 20px 0;
  }

  .dashboard-content-wrapper .dashboard-candi-title img {
    width: 25px;
    height: 25px;
  }

  .track-job-applications {
    width: 100%;
  }

  .track-job-applications .track-app-card-block {
    padding: 0 20px;
  }

  .explore-new-opp .explore-new-opp-container {
    padding: 40px 20px;
  }

  .dashboard-page-wrapper .dashboard-content-wrapper {
    /* height: 100%;
    display: flex; */
    flex-direction: column;
  }

  .actions-pending-wrapper {
    position: relative;
    width: 100%;
    height: auto;
  }

  .explore-new-opp .slick-slider button.slick-arrow {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    background-size: 20px;
  }

  .explore-new-opp .slick-slider button.slick-prev {
    left: -12px;
  }

  .login-page-wrapper .info-text {
    font-size: 12px;
  }

  .forgot-password-dialog .MuiPaper-root {
    width: 100%;
    margin: 0;
  }

  .active-jobs-list .header-wrapper .head-text {
    font-size: 12px;
    font-weight: 400;
  }

  .active-jobs-container .job-card-view.first-job-item {
    transform: scale(1);
  }

  /* .job-app-page-wrapper {
    margin-top: 65px;
  } */

  .active-jobs-list .active-jobs-container {
    padding: 0;
    max-height: 100%;
  }

  .job-full-details .job-full-overview {
    height: 100% !important;
  }

  .active-jobs-container .job-card-view .active-jobs-info .ctc-range .bim-tag {
    position: relative;
    top: 0;
    font-size: 10px;
    padding: 0 4px;
    color: #0E7819;
    background-color: #E7FFEA;
  }

  .active-jobs-list {
    padding: 20px;
  }

  .active-jobs-list .header-wrapper {
    padding: 0;
    margin-bottom: 20px;
  }

  .job-full-details .job-full-details-header .header-bottom .MuiStack-root {
    gap: 15px;
    flex-direction: column-reverse;
  }

  .external-job-app {
    padding: 12px;
  }

  .external-job-app .ext-info-text {
    line-height: 27px;
  }

  .my-internal-jobs .updated-date {
    /* margin-top: 4px; */
    margin-top: 10px;
  }

  .job-full-details .job-full-details-header .title {
    font-size: 16px;
  }

  .active-jobs-container .job-card-view .active-jobs-info .ctc-range {
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
  }

  .active-jobs-container .job-card-view .active-jobs-info .ctc-range .bim-tag {
    position: relative;
    top: 0;
    font-size: 10px;
    padding: 0 4px;
    color: #0E7819;
    background-color: #E7FFEA;
  }

  .applied-resume-date-info .MuiStack-root {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .mob-side-panel .MuiDrawer-paper {
    width: 100%;
  }

  .job-suit-analysis .MuiButton-root {
    font-size: 12px;
  }

  .job-suit-analysis .lock-icon {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .form-action-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* padding: 0 16px 24px; */
    padding: 6px 20px 16px 20px;
    z-index: 1;
    left: 0;
    background-color: #ffffff;
    border-top: 1px solid #C5D1DB;
  }

  .accomplishments {
    margin-bottom: 75px !important;
  }

  .candidate-basic-det-wrapper .done-btn {
    width: 100%;
  }

  .job-details-mob-page .sidebar-content {
    padding: 0 !important;
  }
  .resume-type-toggle-btn .MuiButtonBase-root {
    font-size: 12px;
  }
  .job-full-details .save-btn {
    display: flex;
    height: var(--Card-Bg-Height, 48px);
    padding: 6px var(--Spacing-6x, 20px);
    justify-content: center;
    align-items: center;
    /* gap: var(--Spacing-4x, 12px); */
    border-radius: var(--Spacing-4x, 12px);
    border: 1px solid var(--CTA-Active, #1575C6);
    color: var(--CTA-Active, #1575C6);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }

  .job-full-details .saved-btn svg {
    width: 18px;
    height: 10px;
    margin-right: 3px;
  }

  .job-full-details .saved-btn .MuiButtonBase-root svg {
    font-size: 14px;
  }

  .job-full-details .saved-btn {
    display: flex;
    height: var(--Card-Bg-Height, 48px);
    /* padding: 6px var(--Spacing-6x, 20px); */
    justify-content: center;
    align-items: center;
    /* gap: var(--Spacing-4x, 5px); */
    border-radius: var(--Spacing-4x, 12px);
    border: 1px solid var(--Neutral-400, #9ca3af);
    color: var(--Neutral-400, #9ca3af);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    text-transform: capitalize;
  }

  .job-full-details .saved-btn .MuiButton-startIcon {
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    border: 1px solid var(--Neutral-400, #9ca3af);
    background: var(--Neutral-400, #9ca3af);
  }

  .job-full-details .apply-now-btn {
    display: flex;
    height: var(--Card-Bg-Height, 48px);
    /* padding: 6px var(--Spacing-6x, 40px); */
    justify-content: center;
    align-items: center;
    /* gap: var(--Spacing-4x, 12px); */
    border-radius: var(--Spacing-4x, 12px);
    background: var(--CTA-Active, #1575c6);
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    text-transform: capitalize;
  }

  .job-full-details .apply-now-btn svg {
    width: 12px;
    height: 12px;
  }

  .job-full-details .apply-now-btn .MuiButtonBase-root svg {
    font-size: 14px;
  }
  .resume-replace-btn {
    display: flex;
    height: var(--Card-Bg-Height, 48px);
    /* padding: 6px var(--Spacing-6x, 40px); */
    justify-content: center;
    align-items: center;
    /* gap: var(--Spacing-4x, 12px); */
    border-radius: var(--Spacing-4x, 12px);
    background: var(--CTA-Active, #1575c6);
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    text-transform: capitalize;
  }
  .job-full-details .external-apply-now-btn svg {
    width: 12px;
    height: 12px;
  }

  .job-full-details .external-apply-now-btn .MuiButtonBase-root svg {
    font-size: 14px;
  }

  .action-dialog-card .MuiDialog-paper .title {
    font-size: 16px;
  }

  .my-jobs-tabs .MuiButtonBase-root,
  .my-jobs-tabs .MuiButtonBase-root.Mui-selected {
    font-size: 14px;
    padding: 0;
  }

  .my-jobs-tabs .MuiTabs-root {
    min-height: 35px;
  }

  .resume-type-container .resume-type-card.selected-card {
    transform: scale(1);
  }

  .resume-type-container .resume-type-content {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    overflow-y: auto;
    height: calc(100vh - 180px);
    padding-bottom: 10vh;
  }

  .resume-type-container .resume-type-card {
    margin-bottom: 20px;
  }

  .resume-type-container {
    padding: 20px;
  }

  .my-resume-details {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .my-resume-details .my-resume-details-header {
    flex: 0 0 auto;
  }

  .my-resume-details .my-resume-overview {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .download-resume-drawer .MuiPaper-root {
    padding: 24px 16px 12px;
  }

  .my-resume-details .my-resume-details-header .header-top {
    background: var(--TJ-Grad, linear-gradient(90deg, #1B1464 -3.92%, #2099D6 233.8%));
  }

  .my-resume-details .my-resume-details-header .resume-title {
    color: #ffffff;
  }

  .my-resume-details .my-resume-details-header .last-updated-date {
    color: #C5D1DB;
    font-weight: 400;
  }

  .my-resume-details .my-resume-details-header .resume-action-menu-btn svg path {
    fill: #ffffff;
  }

  .my-resume-details .my-resume-details-card {
    height: 100%;
    border: none;
    border-radius: 0;
  }

  .my-resume-details .my-resume-overview .overview-container {
    height: 100%;
  }

  .my-resume-overview .highlighted-skills .text-block .MuiTypography-root {
    font-size: 8px;
    line-height: 12px;
  }

  .my-resume-overview .highlighted-skills .header-block .MuiTypography-root {
    font-size: 10px;
    line-height: 14px;
  }

  .create-update-resume-container {
    height: 100% !important;
    padding: 0;
  }

  .create-update-resume-wrapper {
    padding: 65px 0 0;
  }

  .create-update-resume-wrapper .MuiContainer-root {
    padding: 0;
  }

  .create-update-resume-wrapper .MuiGrid-root {
    width: 100%;
    margin: 0;
    padding: 0 !important;
  }

  /* .create-update-resume-wrapper .header-section {
    padding: 20px;
    margin: 0;
  } */

  .create-update-resume-wrapper .create-update-resume-card {
    border-radius: 0;
  }

  .create-update-resume-card .MuiCardContent-root {
    padding: 20px;
  }

  .create-update-resume-wrapper .resume-details-row .title {
    width: 230px;
  }

  .resume-details-row .education-info-row {
    position: relative;
    border-radius: 12px;
    border: 1px solid var(--Neutral-200, #C5D1DB);
    background: var(--White, #FFF);
    padding: 12px;
  }

  .create-update-resume-wrapper .resume-details-row .block-title {
    margin-bottom: 8px;
  }

  .create-update-resume-wrapper .resume-details-row .MuiButtonBase-root {
    min-height: 30px;
    padding: 0;
    margin-bottom: 4px;
  }

  .job-app-drawer-container .drawer-header {
    padding: 16px 20px;
  }

  .job-app-drawer-container .drawer-header .delete-btn-icon {
    border-radius: 4px;
    background: #FEE;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 24px;
  }

  .actions-pending-wrapper .actions-header {
    padding: 16px 20px;
  }

  .actions-pending-wrapper .actions-header .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root,
  .actions-pending-wrapper .actions-header .MuiListItemButton-root .view-text {
    font-size: 14px;
  }

  .actions-pending-wrapper .actions-thought-container .thought-text {
    font-size: 12px;
    line-height: 24px;
  }

  .explore-new-opp .slick-slider button.slick-prev,
  .explore-new-opp .slick-slider button.slick-next {
    background-size: 20px !important;
    display: none !important;
  }

  .preview-resume-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 32px !important;
    height: 32px !important;
    background: #1575C6 !important;
    position: absolute !important;
    right: 0px;
    top: -10px;
  }

  .dashboard-page-wrapper .card-wrapper .card-content-top {
    padding: 20px 16px 16px;
  }


  .slick-slider .slick-list {
    overflow: inherit;
  }


  /* .dashboard-page-wrapper .slick-slider .slick-list {
    overflow: inherit;
  } */

  .dashboard-page-wrapper .card-wrapper .card-content-bottom button {
    font-size: 12px;
  }

  .create-update-resume-container {
    height: 100% !important;
    padding: 0;
  }

  .create-update-resume-wrapper {
    padding: 65px 0 0;
  }

  .create-update-resume-wrapper .MuiContainer-root {
    padding: 0;
  }

  .create-update-resume-wrapper .MuiGrid-root {
    width: 100%;
    margin: 0;
    padding: 0 !important;
  }

  .create-update-resume-wrapper .header-section {
    padding: 20px 0px 0px 0px;
    margin: 0;
  }

  .create-update-resume-wrapper .create-update-resume-card {
    border-radius: 0;
  }

  .create-update-resume-card .MuiCardContent-root {
    padding: 20px;
  }

  .create-update-resume-wrapper .resume-details-row .title {
    width: 230px;
  }

  .explore-new-opp .explore-new-opp-container.bg-light-blue {
    position: relative;
    width: 100%;
    display: block;
    padding: 20px !important;
    background-color: #CCE0F4;
  }

  .explore-new-opp .slick-slider .slick-list {
    padding-top: 2rem;
    margin: 0px !important;
  }

  .supercharge-your-career .supercharge-career-container {
    position: relative;
    width: 100%;
    padding: 20px !important;
    background-color: #ffffff;
    padding-bottom: 10vh !important;
  }

  .supercharge-your-career .career-card {
    gap: 12px;
  }

  .supercharge-your-career .career-card .card-head {
    padding: 16px;
  }

  .supercharge-your-career .career-card .card-body {
    padding: 0 16px 20px;
    gap: 20px;
  }

  .supercharge-your-career .career-card .card-head .title {
    font-size: 14px;
  }

  .external-job-app-dialog .title {
    font-size: 16px;
  }

  .external-job-app-dialog .info-text {
    font-size: 12px;
    line-height: 21px;
  }

  .action-dialog-card .MuiButton-outlined {
    border: none;
  }

  .action-dialog-card .MuiButton-text {
    color: var(--CTA-Active, #1575C6);
    text-align: center;
    font-feature-settings: 'ss01' on;
    /* font-family: "Inter", sans-serif !important; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 12px 20px;
    border-radius: 12px;
    text-transform: capitalize;
  }

  .external-job-app-dialog .remove-job-dialog .MuiFormControlLabel-root {
    align-items: center;
    border: none;
  }

  .external-job-app-dialog .remove-job-dialog .MuiFormControlLabel-root .MuiTypography-root {
    color: var(--Light-Neutral-600, #4B5563);
    text-align: center;
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 175% */
  }

  .filter-by-overlay .MuiPaper-root {
    border-radius: 20px;
  }

  .filter-by-overlay .filter-by-tabs-item .MuiButtonBase-root {
    font-size: 13px;
    padding: 24px 12px;
  }

  .filter-by-overlay .filter-by-tabs-content .title {
    font-size: 16px;
  }

  .filter-by-overlay .filter-by-tabs-content .MuiRadioGroup-root .MuiTypography-root {
    font-size: 13px;
    line-height: 16px;
  }

  .filter-by-overlay .filter-by-actions-btn button {
    font-size: 14px;
  }

  .filter-by-overlay .filter-by-actions-btn {
    padding: 24px 16px;
  }

  .filter-by-overlay .filter-by-tabs-content .tab-panel-wrapper .css-19kzrtu {
    padding: 24px 12px !important;
  }

  .filter-by-overlay .filter-by-tabs-content .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 13px;
    line-height: 16px;
  }

  .myJobs-page,
  .myResume-page {
    display: flex;
    height: 100%;
  }

  .job-details-track-bottom .MuiButtonBase-root {
    color: var(--White, #FFF);
    text-align: center;
    font-feature-settings: 'ss01' on;
    font-family: "Inter", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .job-details-track-bottom .MuiButtonBase-root svg {
    font-size: 14px;
  }

  .job-details-track-bottom svg {
    width: 12px;
    height: 12px;
  }

  .job-full-details .job-description {
    margin-bottom: calc(100vh - 50vh);
  }

  .dashboard-page-wrapper .dashboard-content-wrapper {
    padding-bottom: 5vh;
    height: calc(100vh - 20vh);
  }

  .active-jobs-list .active-jobs-container {
    height: calc(100vh - 35vh);
  }

  .chat-bot-drawer-container .drawer-content {
    height: 78vh !important;
  }

  .exp-new-opp-slider .slick-track {
    gap: 16px;
  }

  .external-job-app-dialog .MuiPaper-root {
    width: 100%;
    border-radius: 0;
  }

  .select-resumes-dialog .resume-options-slider-container .slick-slider {
    padding: 0;
  }

  .select-resumes-dialog .resume-options-slider-container .resume-option-item {
    padding: 0;
  }

  .select-resumes-dialog .resume-options-slider-container .slick-slider .slick-list .resume-options-preview {
    height: 100%;
  }

  .select-resumes-dialog .resume-option-item .header-update-select-resume {
    padding: 0.5rem;
  }

  .create-new-resume-slide .inner-container .MuiSvgIcon-root {
    width: 50px;
    height: 50px;
  }

  .create-new-resume-slide .inner-container p {
    font-size: 18px;
  }

  .job-full-details .job-desc-toggle-btn button {
    min-width: 145px;
    height: 30px;
  }

  .job-full-details .job-desc-toggle-btn {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .save-hide-job {
    width: 100%;
  }

  .resume-body {
    height: 80vh;
  }

  .registration-upload-page .regi-upload-page-content {
    height: 100% ;
    padding: 90px 20px 20px !important;
    padding-bottom: 30vh !important;
  }

  .resume-type-toggle-btn .MuiButtonBase-root {
    font-size: 12px;
  }
  .total-work-exp .count-years {
    width: 100%;
  }
  
  .resume-onboard-screen .MuiPaper-root {
    height: 100%;
    border-radius: 0;
  }
  .resume-onboard-screen .MuiPaper-root .MuiCardContent-root {
    height: 100%;
    padding: 40px 20px!important;
    overflow-x: auto;
  }
  .exp-new-opp-slider .slick-prev,
  .exp-new-opp-slider .slick-next {
    display: none !important;
  }
}

.banner-image {
  width: auto;
  height: 100vh;
  background: url('../public/assets/login-image.png') lightgray 50% / cover no-repeat;
}

.text-slider {
  display: inline-block;
  vertical-align: top;
  width: 180px !important;
  height: auto !important;
  overflow: hidden;
}

.text-slider .slick-slide {
  position: relative;
  background: linear-gradient(90deg, #3983FF -3.92%, #55FF39 233.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-text .focus {
  background: linear-gradient(90deg, #3983FF -3.92%, #55FF39 233.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.popup-btn {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid var(--CTA-Active, #1575c6);
  padding: 12px 15px;
}

.my-resume-mobile-footer {
  position: relative;
  padding: 20px 16px;
  background-color: #ffffff;
}

.my-resume-mobile-footer .save-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  /* padding: 6px var(--Spacing-6x, 60px); */
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-4x, 12px);
  /* flex: 1 0; */
  border-radius: var(--Spacing-4x, 12px);
  border: 1px solid var(--CTA-Active, #1575C6);
  color: var(--CTA-Active, #1575C6);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}

.my-resume-mobile-footer .apply-now-btn {
  display: flex;
  height: var(--Card-Bg-Height, 48px);
  padding: 6px var(--Spacing-6x, 30px);
  justify-content: center;
  align-items: center;
  border-radius: var(--Spacing-4x, 12px);
  background: var(--CTA-Active, #1575C6);
  color: var(--White, #FFF);
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}

.myJobs-page,
.myResume-page {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 100px);
}

/* Mobile bottom navigation style */
.mobile-bottom-candidate-nav .MuiPaper-root {
  border-radius: 0;
  box-shadow: none;
  border-top: 1px solid var(--Neutral-100, #E7EBFD);
  background: #FFF;
  padding: 12px 0;
}

.mobile-bottom-candidate-nav .MuiBottomNavigation-root .MuiButtonBase-root .MuiBottomNavigationAction-label {
  color: var(--Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.mobile-bottom-candidate-nav .MuiBottomNavigation-root .MuiButtonBase-root {
  position: relative;
  padding: 0;
  gap: 8px;
}

.mobile-bottom-candidate-nav .MuiBottomNavigation-root .MuiButtonBase-root.active-bottom-nav .MuiBottomNavigationAction-label {
  color: #1575C6;
  font-weight: 600;
}

.mobile-bottom-candidate-nav .MuiBottomNavigation-root .MuiButtonBase-root.active-bottom-nav .MuiSvgIcon-root path {
  fill: #1575C6;
}

.mobile-bottom-candidate-nav .MuiBottomNavigation-root .MuiButtonBase-root.active-bottom-nav::before {
  content: '';
  background-color: #1575C6;
  height: 3px;
  width: 100%;
  position: absolute;
  top: -12px;
  left: 0;
}

/* Registration upload resume style */
.registration-upload-page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.registration-upload-page .regi-upload-page-content {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 100px); */
  height: 100vh;
  overflow-y: auto;
  padding: 100px 42px 42px;
}

.registration-upload-page .regi-upload-page-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.resume-type-toggle-btn .MuiButtonBase-root {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  text-transform: capitalize;
  border: 1px solid #4B5563 !important;
  border-radius: 8px !important;
}

.resume-type-toggle-btn .MuiButtonBase-root:hover {
  background-color: transparent !important;
}

.resume-type-toggle-btn .MuiButtonBase-root.Mui-selected {
  background: var(--CTA-Active, #1575C6) !important;
  color: #ffffff !important;
  border: none !important;
}

.total-work-exp {
  display: flex;
  padding: 12px;
  border-radius: 12px;
  background: var(--Accent-Neutral, #CCE0F4);
  margin-top: 24px;
}

.total-work-exp .info-txt {
  color: var(--Light-Neutral-600, #4B5563);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.total-work-exp .count-years {
  display: flex;
  padding: 8px;
  border-radius: 6px;
  border: 0.5px solid var(--CTA-Active, #1575C6);
  background: #FFF;
}

.total-work-exp .count-years .count {
  color: var(--CTA-Active, #1575C6);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.total-work-exp .count-years .year-txt {
  color: var(--Neutral-400, #9CA3AF);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  /* 175% */
}

.job-type-toggle-wrapper {
  position: relative;
  width: 100%;
  padding: 12px 0;
}

.job-type-toggle .MuiButtonBase-root {
  color: var(--CTA-Active, #1575C6);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */
  padding: 5px 12px;
  border-radius: 30px !important;
  border: 1px solid var(--CTA-Active, #1575C6) !important;
  text-transform: capitalize;
}

.job-type-toggle .MuiButtonBase-root:hover {
  background-color: transparent;
}

.job-type-toggle .MuiButtonBase-root.Mui-selected {
  background-color: #1575C6 !important;
  color: #ffffff;
}

.info-note {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: var(--Warning-50, #FFF6E7);
  margin: 20px 0;
}

.info-note .note-txt {
  color: var(--Neutral-900, #111827);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 192.857% */
}

.text-button {
  color: var(--Primary-Light, #1575C6) !important;
  text-align: center;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
}

.text-button:hover {
  background-color: transparent !important;
}

.interview-prep-overlay .MuiPopover-paper {
  box-shadow: none;
  border-radius: 8px;
  overflow: inherit;
  margin-top: 15px;
}

.interview-prep-container {
  display: flex;
  padding: 12px;
  border-radius: 8px;
  background: var(--Neutral-100, #E7EBFD);
}

.interview-prep-container .title {
  color: var(--Neutral-800, #1F2937);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.interview-prep-container .info-text {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.update-resume-dialog .title {
  color: var(--light-neutral-900-text, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.24px;
  text-align: center;
}

.update-resume-dialog .info-text {
  color: var(--Light-Neutral-600, #4B5563);
  text-align: center;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.update-resume-dialog .MuiFormControlLabel-root .MuiTypography-root {
  color: var(--Light-Neutral-600, #4B5563);
  text-align: center;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.perview-resume {
  display: inline-flex;
  width: 32px;
  height: 48px;
  justify-content: center;
  align-items: center;
}

.perview-resume img {
  max-width: 100%;
  height: auto;
}
.add-resume-dp-container {
  position: relative;
  width: 100%;
}
.add-resume-dropdown-btn {
  color: var(--CTA-Active, #1575C6);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  /* 150% */
  text-transform: capitalize !important;
  padding: 16px !important;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  width: 100%;
  margin: 0 auto !important;
  justify-content: space-between!important;
}

.add-resume-dropdown-btn.active {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.add-resume-dropdown-popover .custom-sec-list {
  width: 388px;
}
.add-resume-dropdown-popover .MuiPaper-root {
  border-radius: 0;
  box-shadow: none;
  border-top: 1px solid #E7EBFD;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.add-resume-dropdown-popover .MuiListItem-root .MuiButtonBase-root {
  padding: 10px 16px;
}

.add-resume-dropdown-popover .MuiListItem-root .MuiButtonBase-root .MuiTypography-root {
  color: var(--CTA-Active, #1575C6) !important;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  /* 150% */
}
.rpv-core__inner-page {
  background-color: transparent !important;
}

.resume-onboarded-card-container img.logo {
  width: 100px;
}
.resume-onboarded-card-container .back-arrow {
  padding: 0;
}

.title-Input{
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.continue-btn:disabled{
  color: grey !important;  /* Change text color to grey */
  border-color: grey !important;  /* Change border to grey */
  background-color: #e0e0e0 !important;  /* Light grey background */
  cursor: not-allowed !important;  
}
.job-preferences-page .main-heading {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif!important;
  font-size: 24px!important;
  font-style: normal;
  font-weight: 600!important;
  line-height: 36px; /* 133.333% */
  text-transform: capitalize;
}
.job-preferences-page .card-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed #9ca3af;
  border-radius: 20px;
}
.job-preferences-page .card-container .card-title {
  display: flex;
  padding: var(--Spacing-6x, 20px) 40px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--Spacing-6x, 20px) var(--Spacing-6x, 20px) 0px 0px;
  background: #DEF1FF;
}

.job-preferences-page .card-container .card-title .title-text {
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
}
.job-preferences-page .card-container .card-title .info-text {
  color: var(--Light-Neutral-500, #6B7280);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.job-preferences-page .card-container .card-body {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
.job-preferences-page .MuiRadioGroup-root .MuiFormControlLabel-root .MuiRadio-root {
  padding-top: 0;
  padding-bottom: 0;
}
.job-preferences-page .MuiRadio-root .MuiSvgIcon-root path {
  fill: #D2D5DB;
}
.job-preferences-page .MuiRadio-root.Mui-checked .MuiSvgIcon-root,
.job-preferences-page .MuiRadio-root.Mui-checked .MuiSvgIcon-root path {
  fill: #1575C6;
}
.job-preferences-page .MuiRadioGroup-root .MuiTypography-root {
  color: var(--Light-Neutral-500, #6B7280);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.edit-icon svg {
  font-size: 16px;
}
.page-heading {
  color: #111827;
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
}
.back-to-page .back-text {
  color: var(--Secondary-Grey-Dark, #808080);
  font-feature-settings: 'ss01' on;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  margin-left: 4px;
} 
.finance-tech-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--Neutral-100, #E7EBFD);
  background: #FAFAFA;
}
.update-resume-accord-wrapper .MuiPaper-root {
  border-radius: 20px!important;
  background: #FAFAFA;
  border: 1px solid #E7EBFD;
  box-shadow: none;
}
.update-resume-accord-wrapper .job-update-form-top {
  position: relative;
  padding: 20px 20px 0;
}
.update-resume-accord-wrapper .job-update-form-bottom {
  border-radius: 20px;
  border-top: 1px solid var(--Neutral-100, #E7EBFD);
  background: #FFF;
  padding: 20px;
}
.prof-summary-wrapper .add-btn:focus,
.candidate-basic-det-wrapper .add-btn:focus,
.add-cus-sec-btn:focus {
  background-color: #b2d2ec;
}
.exp-new-opp-slider .slick-prev, 
.exp-new-opp-slider .slick-next {
  color: #1575c6;
  z-index: 10; 
}
.exp-new-opp-slider .slick-prev::before, 
.exp-new-opp-slider .slick-next::before {
  color: #1575c6;
  font-size: 24px; 
}
